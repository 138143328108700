import { FC, useCallback } from "react";

import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import set from "lodash/set";
import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";

import { isEmpty, numberTransformer } from "../../utils";

import { Field } from "../Field";
import { NumericInput } from "../NumericInput";
import { Line, Station } from "../../hooks/region";
import { trainTypeWalks } from "../../options";

export interface TrainsFieldProps {
  value: Room[];
  onChange: (value: Room[]) => void;
  lines: Line[];
  stationsByLine: Record<string, Station[]>;
}

export const defaultTrains = [{}];

const MAX_TRAIN = 4;
export const TrainsField: FC<TrainsFieldProps> = ({
  lines,
  stationsByLine,
  value,
  onChange,
}) => {
  const handleAdd = useCallback(() => {
    if (value.length < MAX_TRAIN) {
      onChange([...value, cloneDeep(defaultTrains[0]) as any as Room]);
    }
  }, [value, onChange]);

  const handleRemove = useCallback(() => {
    if (value.length > 1) {
      onChange(value.slice(0, value.length - 1));
    }
  }, [value, onChange]);

  const handleInput = useCallback(
    (
      room,
      path,
      index,
      transform = (value: string) => value,
      valuer = (e: any) => e.target.value
    ) => {
      const item = get(room, path);
      return {
        value: item == null ? "" : item,
        onChange: (e: any) =>
          onChange(
            value.map((item, i) => {
              if (i === index) {
                const vl = set(item, path, transform(valuer(e)));
                return !isEmpty(vl) &&
                  typeof vl === "object" &&
                  !Array.isArray(vl)
                  ? { ...vl }
                  : vl;
              }
              return item;
            })
          ),
      };
    },
    [onChange, value]
  );

  const amountOfTrain = value.length;

  return (
    <>
      {value.map((train: any, i: number) => {
        return (
          <Field key={i} required label="路線">
            <div data-role="field-group-block">
              <div data-divider="true" data-role="field-group">
                <select
                  key={`sel-line-${+!!lines.length}`}
                  {...handleInput(train, "line", i, numberTransformer)}
                >
                  <option value="">--沿線を選択してください。--</option>
                  {lines.map((line) => (
                    <option key={line.id} value={line.id}>
                      {line.name}
                    </option>
                  ))}
                </select>
                <select
                  {...handleInput(train, "station", i, numberTransformer)}
                >
                  <option value="">--駅を選択してください。--</option>
                  {(stationsByLine[train.line] || []).map((station) => (
                    <option key={station.id} value={station.id}>
                      {station.name}
                    </option>
                  ))}
                </select>
              </div>
              <div data-divider="true" data-role="field-group">
                <select
                  {...handleInput(train, "walkType", i, numberTransformer)}
                >
                  <option value="">--駅から--</option>
                  {trainTypeWalks.map((opt) => (
                    <option key={opt.value} value={opt.value}>
                      {opt.label}
                    </option>
                  ))}
                </select>
                <div data-divider="true" data-role="field-group">
                  <NumericInput
                    {...handleInput(train, "walktime", i, numberTransformer)}
                    min={0}
                    placeholder="徒歩(分)"
                  />
                  <NumericInput
                    min={0}
                    placeholder="バス (分)"
                    {...handleInput(train, "bustime", i, numberTransformer)}
                  />
                </div>
              </div>
            </div>
            <div data-role="addon-group">
              <div
                aria-disabled={
                  i !== amountOfTrain - 1 || amountOfTrain >= MAX_TRAIN
                }
                data-mode="primary"
                data-role="addon"
                data-addon="pointer"
                onClick={handleAdd}
              >
                <FontAwesomeIcon icon={faPlus} />
              </div>
              <div
                aria-disabled={i !== amountOfTrain - 1 || amountOfTrain <= 1}
                data-mode="danger"
                data-role="addon"
                data-addon="pointer"
                onClick={handleRemove}
              >
                <FontAwesomeIcon icon={faMinus} />
              </div>
            </div>
          </Field>
        );
      })}
    </>
  );
};
