import classes from "./styles.module.scss";

export interface ErrorsMessageProps {
  errors: string[];
}

const ErrorsMessage: React.FC<ErrorsMessageProps> = ({ errors }) => {
  return errors.length ? (
    <div className={classes.root}>
      {errors.map((err, i) => (
        <div key={i} className={classes.itemError}>
          {err}
        </div>
      ))}
    </div>
  ) : null;
};

export default ErrorsMessage;
