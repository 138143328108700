export const ownerShipType = [
  {
    value: 2,
    label: "１棟所有",
  },
  {
    value: 1,
    label: "区分所有",
  },
];

export const buildingTypes = [
  {
    value: 1,
    label: "アパート",
  },
  {
    value: 2,
    label: "マンション",
  },
  {
    value: 3,
    label: "戸建",
  },
  {
    value: 4,
    label: "テラスハウス",
  },
  {
    value: 5,
    label: "シェアハウス",
  },
  {
    value: 6,
    label: "ビル",
  },
  {
    value: 7,
    label: "工場・倉庫",
  },
];

export const buildingStructures = [
  {
    value: 1,
    label: "木造",
  },
  {
    value: 2,
    label: "鉄骨造",
  },
  {
    value: 3,
    label: "鉄筋コンクリート造",
  },
  {
    value: 4,
    label: "鉄骨鉄筋コンクリート造",
  },
  {
    value: 5,
    label: "プレキャストコンクリート",
  },
  {
    value: 6,
    label: "その他",
  },
];

export const priceRanges = [
  {
    value: 1,
    label: "0～10万円",
  },
  {
    value: 2,
    label: "10万円～20万円",
  },
  {
    value: 3,
    label: "10万円～20万円",
  },
];

export const availableDateSeason = [
  {
    label: "上旬",
    value: 10,
  },
  {
    label: "中旬",
    value: 20,
  },
  {
    label: "下旬",
    value: 28,
  },
];

export const trainTypeWalks = [
  {
    label: "駅から徒歩",
    value: 1,
  },
  {
    label: "駅からバス",
    value: 2,
  },
];

export const parkingTypes = [
  {
    label: "空きあり",
    value: 1,
  },
  {
    label: "空きなし",
    value: 2,
  },
];

export const buildingArticleItems = [
  {
    label: "オートロック",
    value: 25,
  },
  {
    label: "防犯カメラ",
    value: 64,
  },
  {
    label: "タワーマンション",
    value: 54,
  },
  {
    label: "管理人常駐",
    value: 27,
  },
  {
    label: "エレベーター",
    value: 40,
  },
  {
    label: "宅配ボックス",
    value: 28,
  },
  {
    label: "フロントサービス",
    value: 52,
  },
  {
    label: "免震構造",
    value: 56,
  },
];

export const positionArticleItems = [
  {
    label: "1階の物件",
    value: 1,
  },
  {
    label: "2階以上",
    value: 2,
  },
  {
    label: "最上階",
    value: 3,
  },
  {
    label: "角部屋",
    value: 4,
  },
  {
    label: "南向き",
    value: 5,
  },
];

export const conditionArticleItems = [
  {
    label: "楽器相談",
    value: 6,
  },
  {
    label: "２人入居可",
    value: 8,
  },
  {
    label: "女性限定",
    value: 9,
  },
  {
    label: "高齢者歓迎",
    value: 10,
  },
  {
    label: "ペット相談可",
    value: 11,
  },
  {
    label: "保証人不要",
    value: 12,
  },
  {
    label: "特優賃（特定優良賃貸住宅）",
    value: 13,
  },
  {
    label: "ファミリー向け",
    value: 63,
  },
];

export const kitchenArticleItems = [
  {
    label: "ガスコンロ設置可",
    value: 14,
  },
  {
    label: "ガスコンロ付き",
    value: 70,
  },
  {
    label: "IHコンロ",
    value: 15,
  },
  {
    label: "コンロ２口以上",
    value: 16,
  },
  {
    label: "システムキッチン",
    value: 17,
  },
  {
    label: "カウンターキッチン",
    value: 18,
  },
];

export const bathArticleItems = [
  {
    label: "バス・トイレ別",
    value: 19,
  },
  {
    label: "ユニットバス",
    value: 62,
  },
  {
    label: "追い焚き",
    value: 20,
  },
  {
    label: "温水洗浄便座",
    value: 21,
  },
  {
    label: "浴室乾燥機",
    value: 22,
  },
  {
    label: "独立洗面台",
    value: 23,
  },
];

export const airConditionArticleItems = [
  {
    label: "ガス暖房",
    value: 29,
  },
  {
    label: "石油暖房",
    value: 30,
  },
  {
    label: "エアコン",
    value: 31,
  },
  {
    label: "床暖房",
    value: 32,
  },
];

export const servicesArticleItems = [
  {
    label: "TVモニタ付インターホン",
    value: 26,
  },
  {
    label: "都市ガス",
    value: 33,
  },
  {
    label: "オール電化",
    value: 34,
  },
  {
    label: "フローリング",
    value: 35,
  },
  {
    label: "専用庭",
    value: 36,
  },
  {
    label: "バルコニー",
    value: 37,
  },
  {
    label: "バリアフリー",
    value: 38,
  },
  {
    label: "ロフト付き",
    value: 39,
  },
  {
    label: "メゾネット",
    value: 65,
  },
  {
    label: "室内洗濯機置場",
    value: 24,
  },
  {
    label: "室外洗濯機置場",
    value: 69,
  },
  {
    label: "防音ガラス",
    value: 66,
  },
];

export const communicationArticleItems = [
  {
    label: "CATV",
    value: 41,
  },
  {
    label: "CSアンテナ",
    value: 42,
  },
  {
    label: "BSアンテナ",
    value: 43,
  },
  {
    label: "ブロードバンド",
    value: 45,
  },
];

export const generalArticleItems = [
  {
    label: "ウォークインクローゼット",
    value: 49,
  },
  {
    label: "リフォーム・リノベーション済",
    value: 50,
  },
  {
    label: "家具・家電付",
    value: 51,
  },
  {
    label: "デザイナーズ",
    value: 53,
  },
];

export const boshuuchuus = [
  {
    label: "募集中",
    value: "募集中",
  },
  {
    label: "非募集中",
    value: "非募集中",
  },
];
