import { ImgHTMLAttributes, useMemo } from "react";

export interface PhotoProps
  extends Omit<ImgHTMLAttributes<HTMLImageElement>, "src"> {
  src: string | File;
}

function isBase64(str: string) {
  try {
    window.atob(str.replace(/data:[a-z/]+;base64,/, ""));
    return true;
  } catch (e) {
    return false;
  }
}

export const Photo: React.FC<PhotoProps> = ({ src, ...props }) => {
  const path = useMemo(() => {
    return typeof src === "string"
      ? isBase64(src)
        ? src
        : `${process.env.REACT_APP_IMAGE_HOST}${src}`
      : URL.createObjectURL(src);
  }, [src]);

  return <img src={path} alt="" {...props} />;
};
