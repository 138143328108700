import { InputHTMLAttributes, forwardRef, useCallback } from "react";
import { getNumeric, isEmpty } from "../../utils";

export interface NumericInputProps
  extends InputHTMLAttributes<HTMLInputElement> {
  min?: number;
  max?: number;
  integer?: boolean;
}

export const NumericInput: React.FC<NumericInputProps> = forwardRef<
  HTMLInputElement,
  NumericInputProps
>(({ onChange, onBlur, min, max, integer, ...props }, ref) => {
  const handleInputChange = useCallback(
    (e) => {
      const {
        target: { value },
      } = e;
      let v = getNumeric(value, integer);
      if (typeof v === "number") {
        if (min != null) {
          v = v < min ? min : v;
        }
        if (max != null) {
          v = v > max ? max : v;
        }
      }
      e.target.value = v;
      onChange && onChange(e);
    },
    [onChange, integer, min, max]
  );

  const handleBlur = useCallback(
    (e) => {
      const {
        target: { value },
      } = e;
      if (isNaN(value as any)) {
        e.target.value = "";
      }
      e.target.value = isEmpty(e.target.value) ? "" : Number(e.target.value);
      onChange && onChange(e);
      onBlur && setTimeout(() => onBlur(e), 50);
    },
    [onChange, onBlur]
  );

  return (
    <input
      ref={ref}
      onBlur={handleBlur}
      onChange={handleInputChange}
      {...props}
    />
  );
});
