import { CSSProperties, FC, ReactElement } from "react";

import classes from "./styles.module.scss";

interface CommonProps {
  required?: boolean;
  children: ReactElement<HTMLInputElement> | ReactElement<HTMLInputElement>[];
}

export type FieldProps = CommonProps &
  (
    | {
        label: string;
        icon?: never;
        iconColor?: never;
      }
    | {
        label?: never;
        icon: React.ReactElement;
        iconColor?: string;
      }
  );

export const Field: FC<FieldProps> = ({
  iconColor = "#34495e",
  required,
  label,
  icon,
  children,
}) => {
  return (
    <div className={classes.root}>
      <div
        style={
          {
            "--icon-color": iconColor,
          } as CSSProperties
        }
        data-role={!!icon ? "icon" : "text"}
        className={classes.label}
      >
        {required && <sup>*</sup>}
        {label ? `${label}:` : icon}
      </div>
      <div className={classes.inputWrap}>{children}</div>
    </div>
  );
};
