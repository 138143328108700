import { useCallback } from "react";
import { useApi, useCaller } from "./api";
import { PhotoGroup } from "../enums";
import { useConnectRender } from "use-connect-render";

export function useProduct() {
  const { post, put, get } = useApi("/products");
  const { del } = useApi("/photos");
  const [, caller] = useCaller();

  const { pusher } = useConnectRender("product", {
    productList: [],
    products: {},
    rooms: {},
    photos: {},
  });

  const saveProductRooms = useCallback(
    (id: string, rooms: Room[]) => {
      pusher("rooms", (prev: Record<string, Room[]>) => {
        return {
          ...prev,
          [id]: rooms,
        };
      });
    },
    [pusher]
  );

  const saveProductPhotos = useCallback(
    (
      id: string,
      photos?: ProductPhotos,
      onMap?: (photos: ProductPhotos) => ProductPhotos
    ) => {
      pusher("photos", (prev: Record<string, ProductPhotos>) => {
        return {
          ...prev,
          [id]: onMap ? onMap(prev[id]) : photos!,
        };
      });
    },
    [pusher]
  );

  const saveProducts = useCallback(
    (
      commingProducts: (PartialEntity<Product> & { id: string })[],
      onMap?: (product: Product) => Product
    ) => {
      pusher("products", (prev: any) => {
        const products = commingProducts.reduce(
          (obj, product) => {
            if (obj[product.id]) {
              obj[product.id] = {
                ...obj[product.id],
                ...product,
              } as Product;
            } else {
              obj[product.id] = product as Product;
            }
            if (onMap) {
              obj[product.id] = onMap(obj[product.id]!);
            }
            return obj;
          },
          { ...prev }
        );
        return products;
      });
    },
    [pusher]
  );

  const saveProductList = useCallback(
    (products: Product[]) => {
      pusher("productList", products);
      saveProducts(products);
    },
    [pusher, saveProducts]
  );

  const getList = useCallback(async () => {
    const products = await caller(() => get("/"), {
      // successMessage: "賃貸物件一覧が取り込みました。",
      successMessage: "",
    });
    if (products?.length) {
      saveProductList(
        products.map(({ _id, ...product }: any) => ({
          data: product.data,
          ...product,
          id: _id,
        }))
      );
      for (const product of products) {
        if (product.rooms) {
          saveProductRooms(
            product._id,
            // eslint-disable-next-line
            product.rooms.map((room: Room & { _id: string }) => ({
              ...room,
              id: room._id,
            }))
          );
        }
        if (product.photos) {
          saveProductPhotos(
            product._id,
            product.photos.reduce(
              // eslint-disable-next-line
              (obj: ProductPhotos, photo: Photo & { _id: string }) => {
                obj[
                  photo.group === PhotoGroup.BUILDING ? "buildings" : "rooms"
                ].push({
                  ...photo,
                  id: photo._id,
                });
                return obj;
              },
              { buildings: [], rooms: [] }
            )
          );
        }
      }
    }
  }, [get, caller, saveProductPhotos, saveProductRooms, saveProductList]);

  const getOne = useCallback(
    async (id: string) => {
      const product = await caller(() => get(`/${id}`), {
        successMessage: "",
        // successMessage: "賃貸物件が取り込みました。",
      });
      if (product?._id) {
        saveProducts([{ id, data: product.data }]);
        saveProductRooms(
          id,
          product.rooms.map((room: Room & { _id: string }) => ({
            ...room,
            id: room._id,
          }))
        );
        saveProductPhotos(
          id,
          product.photos.reduce(
            (obj: ProductPhotos, photo: Photo & { _id: string }) => {
              obj[
                photo.group === PhotoGroup.BUILDING ? "buildings" : "rooms"
              ].push({
                ...photo,
                id: photo._id,
              });
              return obj;
            },
            { buildings: [], rooms: [] }
          )
        );
      }
    },
    [get, caller, saveProductPhotos, saveProductRooms, saveProducts]
  );

  const add = useCallback(
    async ({ data }: PartialEntity<Product>) => {
      const product = await caller(
        () =>
          post({
            data,
          }),
        {
          successMessage: "賃貸物件を追加しました。",
        }
      );
      product && saveProducts([{ id: product._id, ...product }]);
      return product;
    },
    [post, caller, saveProducts]
  );

  const edit = useCallback(
    async (id, { data }: PartialEntity<Product>) => {
      const product = await caller(
        () =>
          put(`/${id}`, {
            data,
          }),
        {
          successMessage: "賃貸物件を編集しました。",
        }
      );
      product && saveProducts([{ id: product._id, ...product }]);
      return product;
    },
    [put, caller, saveProducts]
  );

  const savePhoto = useCallback(
    async (id: string, data: FormData | Photo) => {
      let photo: Photo & { _id: string };
      if (data instanceof FormData) {
        photo = await caller(() => put(`/${id}/photo`, data), {
          successMessage: "画像を保存しました。",
        });
        if (!photo) {
          return;
        }
        const group =
          (data.get("group") as PhotoGroup) === PhotoGroup.BUILDING
            ? "buildings"
            : "rooms";
        saveProductPhotos(id, undefined, (photos) => ({
          ...photos,
          [group]: [...photos[group], { ...photo, id: photo._id }],
        }));
      } else {
        photo = await caller(() => put(`/${id}/photo-info`, data), {
          successMessage: "画像を保存しました。",
        });
        if (!photo) {
          return;
        }
        const group =
          (data.group as PhotoGroup) === PhotoGroup.BUILDING
            ? "buildings"
            : "rooms";

        saveProductPhotos(id, undefined, (photos) => ({
          ...photos,
          [group]: photos[group].map((item) =>
            item.id === photo._id
              ? {
                  ...item,
                  ...photo,
                }
              : item
          ),
        }));
      }
      return photo;
    },
    [put, caller, saveProductPhotos]
  );

  const removePhoto = useCallback(
    (id: string, _group: PhotoGroup, photoId: string) => {
      const res = caller(() => del(`/${photoId}`), {
        successMessage: "画像が削除されました。",
      });
      if (!res) {
        return;
      }
      const group = _group === PhotoGroup.BUILDING ? "buildings" : "rooms";
      saveProductPhotos(id, undefined, (photos) => ({
        ...photos,
        [group]: photos[group].filter((item) => item.id !== photoId),
      }));
      return res;
    },
    [del, caller, saveProductPhotos]
  );

  const saveRooms = useCallback(
    async (id: string, data: Room[]) => {
      const rooms = await caller(
        () =>
          put(`/${id}/rooms`, {
            rooms: data,
          }),
        {
          successMessage: "部屋が保存されました。",
        }
      );
      if (rooms) {
        saveProductRooms(id, rooms);
      }
      return rooms;
    },
    [put, caller, saveProductRooms]
  );

  return {
    getOne,
    getList,
    add,
    edit,
    savePhoto,
    saveRooms,
    removePhoto,
  };
}
