import { FC, useCallback, useEffect } from "react";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router";
import { Controller } from "react-hook-form";

import { useFormValidate } from "../../hooks/form";
import { useProduct } from "../../hooks/product";
import { useRegion } from "../../hooks/region";

import { defaultTrains, TrainsField } from "./TrainsField";

import { Field } from "../Field";
import ErrorsMessage from "../ErrorsMessage";
import { NumericInput } from "../NumericInput";

import {
  buildingArticleItems,
  parkingTypes,
  ownerShipType,
  buildingTypes,
  buildingStructures,
  priceRanges,
} from "../../options";

import { numberTransformer } from "../../utils";

import classes from "./styles.module.scss";

export type BuildingFormProps = {
  productId?: string;
  buildingData?: BuildingData;
};

const OLDEST_YEAR = 1950;

export const BuildingForm: FC<BuildingFormProps> = ({
  productId,
  buildingData,
}) => {
  const history = useHistory();
  const { add, edit } = useProduct();

  const { getPreviousValues } = useFormValidate((yup) => ({}));
  const dfTrains =
    getPreviousValues("building-form")?.trains ||
    (buildingData?.lines.length
      ? buildingData.lines.map((line, index) => ({
          line,
          station: buildingData.stations[index],
          walkType: buildingData.walkTypes[index],
          walktime: buildingData.walktimes[index],
          bustime: buildingData.bustimes[index],
        }))
      : defaultTrains);

  const {
    control,
    bindFromPreviousValues,
    cachePreviousValues,
    errorMessages,
    handleSubmit,
    register,
    watch,
    setValue,
    trigger,
  } = useFormValidate(
    (yup) => {
      return {
        name: yup.string().required().label("物件名"),
        zipCode: yup.string().required().label("郵便番号"),
        street: yup.string().required().label("番地"),
        trains: yup
          .array()
          .of(
            yup.object().shape({
              line: yup.trueNumber().required().label("沿線"),
              station: yup.trueNumber().required().label("駅"),
              walkType: yup.trueNumber().required().label("駅から"),
              walktime: yup
                .trueNumber()
                .required()
                .label("徒歩(分)")
                .when("walkType", {
                  is: 1,
                  then: yup.trueNumber().label("徒歩(分)").required(),
                }),
              bustime: yup
                .trueNumber()
                .label("バス (分)")
                .when("walkType", {
                  is: 2,
                  then: yup.trueNumber().label("バス (分)").required(),
                }),
            })
          )
          .required(),
        prefecture: yup.trueNumber().required().label("都道府県"),
        city: yup.trueNumber().required().label("市区郡"),
        area: yup.trueNumber().required().label("区町村"),
        ownerShipType: yup.trueNumber().required().label("所有種別"),
        totalDoor: yup
          .trueNumber()
          .label("Total door")
          .required()
          .label("総戸数"),
        type: yup.trueNumber().required().label("物件種別"),
        structure: yup.trueNumber().label("構造"),
        priceRange: yup.trueNumber().required().label("初期費用"),
        constructionYear: yup.trueNumber().required().label("建築年"),
        constructionMonth: yup.trueNumber().required().label("建築月"),
        floorMax: yup.trueNumber().required().min(0).label("階数地上"),
        floorUnderMax: yup
          .trueNumber()
          .required()
          .min(0)
          .max(2)
          .label("階数地下"),
        parkingCar: yup.object().shape({
          enabled: yup.boolean().required().label("駐車場"),
          type: yup
            .trueNumber()
            .label("駐車場")
            .when("enabled", {
              is: true,
              then: yup.trueNumber().required().label("駐車場"),
            }),
          fee: yup
            .trueNumber()
            .label("Parking car fee")
            .when("enabled", {
              is: true,
              then: yup.trueNumber().required().label("駐車料金"),
            }),
        }),
        parkingBike: yup.object().shape({
          enabled: yup.boolean().required().label("バイク置き場"),
          type: yup
            .trueNumber()
            .label("バイク置き場")
            .when("enabled", {
              is: true,
              then: yup.trueNumber().required().label("バイク置き場"),
            }),
          fee: yup
            .trueNumber()
            .label("バイク置き場料金")
            .when("enabled", {
              is: true,
              then: yup.trueNumber().required().label("バイク置き場料金"),
            }),
        }),
        parkingBicycle: yup.object().shape({
          enabled: yup.boolean().required().label("駐輪場"),
          type: yup
            .trueNumber()
            .label("駐輪場")
            .when("enabled", {
              is: true,
              then: yup.trueNumber().required().label("駐輪場"),
            }),
          fee: yup
            .trueNumber()
            .label("駐輪場料金")
            .when("enabled", {
              is: true,
              then: yup.trueNumber().required().label("駐輪場料金"),
            }),
        }),
        articleItems: yup.array().of(yup.number()).label("Article items"),
      };
    },
    {
      defaultValues: {
        articleItems: [],
        trains: dfTrains,
      },
    }
  );

  useEffect(() => {
    return () => {
      if (productId) {
        cachePreviousValues("building-form");
      }
    };
  }, [productId, cachePreviousValues]);

  const { prefecture, cities, areas, lines, postal, stationsByLine, getAreas } =
    useRegion(watch("zipCode"));

  useEffect(() => {
    bindFromPreviousValues("building-form", () => {
      if (buildingData) {
        setValue("name", buildingData.name);
        setValue("zipCode", buildingData.zipCode);
        setValue("street", buildingData.street);
        setValue("city", buildingData.city);
        setValue("area", buildingData.area);
        setValue("ownerShipType", buildingData.ownerShipType);
        setValue("totalDoor", buildingData.totalDoor);
        setValue("type", buildingData.type);
        setValue("structure", buildingData.structure);
        setValue("constructionYear", buildingData.constructionYear);
        setValue("constructionMonth", buildingData.constructionMonth);
        setValue("priceRange", buildingData.priceRange);
        setValue("floorMax", buildingData.floorMax);
        setValue("floorUnderMax", buildingData.floorUnderMax);
        setValue("parkingCar", buildingData.parkingCar || {});
        setValue("parkingBike", buildingData.parkingBike || {});
        setValue("parkingBicycle", buildingData.parkingBicycle || {});
        setValue("articleItems", buildingData.articleItems || []);
      }
    });
  }, [bindFromPreviousValues, setValue, buildingData]);

  const selectedCity = watch("city");

  const onSubmit = useCallback(
    async (
      _data: Omit<
        BuildingData,
        "lines" | "stations" | "walkTypes" | "walktimes" | "bustimes"
      > & {
        trains: {
          line: BuildingData["lines"][0];
          station: BuildingData["stations"][0];
          walkType: BuildingData["walkTypes"][0];
          walktime: BuildingData["walktimes"][0];
          bustime: BuildingData["bustimes"][0];
        }[];
      }
    ) => {
      const data: BuildingData = {
        ..._data,
        ..._data.trains.reduce(
          (obj: any, train) => {
            obj.lines.push(train.line);
            obj.stations.push(train.station);
            obj.walkTypes.push(train.walkType);
            obj.walktimes.push(train.walktime);
            obj.bustimes.push(train.bustime);
            return obj;
          },
          {
            lines: [],
            stations: [],
            walkTypes: [],
            walktimes: [],
            bustimes: [],
          }
        ),
        trains: undefined,
      };
      if (!cities.some((item) => item.id === data.city)) {
        setValue("city", null);
        setTimeout(trigger, 10);
        return;
      }
      if (!areas.some((item) => item.id === data.area)) {
        setValue("area", null);
        setTimeout(trigger, 10);
        return;
      }
      if (buildingData) {
        await edit(productId, { data });
      } else {
        const res = await add({ data });
        if (res._id) {
          history.push(`/form/${res._id}`);
        }
      }
    },
    [
      add,
      edit,
      areas,
      cities,
      trigger,
      setValue,
      productId,
      buildingData,
      history,
    ]
  );

  useEffect(() => {
    getAreas(selectedCity);
  }, [selectedCity, getAreas]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.generalSection}>
      <div className={classes.title}>建物</div>
      <div className={classes.fields}>
        <Field required label="物件名">
          <input {...register("name")} />
        </Field>
        <Field required label="郵便番号">
          <div data-divider="true" data-role="field-group">
            <input {...register("zipCode")} />
            <div data-role="note" data-wrap="false">
              郵便番号が不明な場合、
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.post.japanpost.jp/zipcode/"
              >
                こちら
              </a>
              で調べられます。
            </div>
          </div>
        </Field>
        <Field required label="地域 1">
          {prefecture ? (
            <select {...register("prefecture")} value={prefecture.id}>
              <option value={prefecture.id}>{prefecture.name}</option>
            </select>
          ) : (
            <input disabled placeholder="都道府県" />
          )}
          <select key={`sel-city-${+!!cities.length}`} {...register("city")}>
            <option value="">--市区郡--</option>
            {cities.map((city) => (
              <option key={city.id} value={city.id}>
                {city.name}
              </option>
            ))}
          </select>
          <select key={`sel-area-${+!!areas.length}`} {...register("area")}>
            <option value="">--区町村--</option>
            {areas.map((area) => (
              <option key={area.id} value={area.id}>
                {area.name}
              </option>
            ))}
          </select>
        </Field>
        <Field required label="番地">
          <input {...register("street")} defaultValue={postal?.street} />
        </Field>

        <Controller
          name="trains"
          control={control}
          defaultValue={dfTrains}
          render={({ field: { onChange, value } }) => {
            return (
              <TrainsField
                stationsByLine={stationsByLine}
                lines={lines}
                value={value}
                onChange={onChange}
              />
            );
          }}
        />

        <Field required label="所有種別">
          <select
            {...register("ownerShipType", {
              setValueAs: numberTransformer,
            })}
          >
            <option value="">--所有種別を選択してください。--</option>
            {ownerShipType.map((opt) => (
              <option key={opt.value} value={opt.value}>
                {opt.label}
              </option>
            ))}
          </select>
          <NumericInput
            integer
            min={1}
            placeholder="総戸数"
            {...register("totalDoor", {
              setValueAs: numberTransformer,
            })}
          />
        </Field>
        <Field required label="物件種別">
          <select
            {...register("type", {
              setValueAs: numberTransformer,
            })}
          >
            <option value="">--物件種別を選択してください。--</option>
            {buildingTypes.map((opt) => (
              <option key={opt.value} value={opt.value}>
                {opt.label}
              </option>
            ))}
          </select>
        </Field>
        <Field label="構造">
          <select
            {...register("structure", {
              setValueAs: numberTransformer,
            })}
          >
            <option value="">--構造を選択してください。--</option>
            {buildingStructures.map((opt) => (
              <option key={opt.value} value={opt.value}>
                {opt.label}
              </option>
            ))}
          </select>
        </Field>
        <Field required label="建築年月">
          <select
            {...register("constructionYear", {
              setValueAs: numberTransformer,
            })}
          >
            <option value="">--年--</option>
            {Array.from({ length: 74 }).map((_, i) => (
              <option key={i} value={OLDEST_YEAR + i}>
                {OLDEST_YEAR + i}
              </option>
            ))}
          </select>
          <select
            defaultValue=""
            {...register("constructionMonth", {
              setValueAs: numberTransformer,
            })}
          >
            <option value="">--月築--</option>
            {Array.from({ length: 12 }).map((_, i) => (
              <option key={i} value={i + 1}>
                {i + 1}
              </option>
            ))}
          </select>
        </Field>
        <Field required label="建物全体の階数">
          <NumericInput
            integer
            min={0}
            placeholder="地上"
            {...register("floorMax", {
              setValueAs: numberTransformer,
            })}
          />
          <NumericInput
            integer
            min={0}
            max={2}
            placeholder="地下"
            {...register("floorUnderMax", {
              setValueAs: numberTransformer,
            })}
          />
        </Field>
        <Field required label="初期費用">
          <select
            {...register("priceRange", {
              setValueAs: numberTransformer,
            })}
          >
            <option value="">--初期費用概算を選択--</option>
            {priceRanges.map((opt) => (
              <option key={opt.value} value={opt.value}>
                {opt.label}
              </option>
            ))}
          </select>
        </Field>
        <Field label="駐車場">
          <label>
            <input {...register("parkingCar.enabled")} type="checkbox" />
            <span>駐輪場あり</span>
          </label>
          <div data-divider="true" data-role="field-group">
            <select
              disabled={!watch("parkingCar.enabled")}
              {...register("parkingCar.type", {
                setValueAs: numberTransformer,
              })}
            >
              <option value="">--駐車場を選択してください。--</option>
              {parkingTypes.map((opt) => (
                <option key={opt.value} value={opt.value}>
                  {opt.label}
                </option>
              ))}
            </select>
            <input
              disabled={!watch("parkingCar.enabled")}
              placeholder="駐車料金"
              {...register("parkingCar.fee", {
                setValueAs: numberTransformer,
              })}
            />
          </div>
        </Field>
        <Field label="バイク置き場">
          <label>
            <input {...register("parkingBike.enabled")} type="checkbox" />
            <span>バイク置き場あり</span>
          </label>
          <div data-divider="true" data-role="field-group">
            <select
              disabled={!watch("parkingBike.enabled")}
              {...register("parkingBike.type", {
                setValueAs: numberTransformer,
              })}
            >
              <option value="">--バイク置き場を選択してください。--</option>
              {parkingTypes.map((opt) => (
                <option key={opt.value} value={opt.value}>
                  {opt.label}
                </option>
              ))}
            </select>
            <input
              disabled={!watch("parkingBike.enabled")}
              placeholder="バイク置き場料金"
              {...register("parkingBike.fee", {
                setValueAs: numberTransformer,
              })}
            />
          </div>
        </Field>
        <Field label="駐輪場">
          <label>
            <input {...register("parkingBicycle.enabled")} type="checkbox" />
            <span>駐輪場あり</span>
          </label>
          <div data-divider="true" data-role="field-group">
            <select
              disabled={!watch("parkingBicycle.enabled")}
              {...register("parkingBicycle.type", {
                setValueAs: numberTransformer,
              })}
            >
              <option value="">--駐輪場を選択してください。--</option>
              {parkingTypes.map((opt) => (
                <option key={opt.value} value={opt.value}>
                  {opt.label}
                </option>
              ))}
            </select>
            <input
              disabled={!watch("parkingBicycle.enabled")}
              placeholder="駐輪料金"
              {...register("parkingBicycle.fee", {
                setValueAs: numberTransformer,
              })}
            />
          </div>
        </Field>
        <Field label="セキュリティ">
          <Controller
            name="articleItems"
            control={control}
            render={({ field: { onChange, value } }) => {
              const hash = value.reduce(
                (hs: Record<string, true>, item: number) => {
                  hs[item] = true;
                  return hs;
                },
                {}
              );

              return (
                <div data-wrap="true" data-role="field-group">
                  {buildingArticleItems.map((item) => (
                    <label key={item.value}>
                      <input
                        value={item.value}
                        checked={!!hash[item.value]}
                        onChange={({ target }) => {
                          hash[item.value] = target.checked;
                          onChange(
                            Object.keys(hash)
                              .filter((k) => hash[k])
                              .map((k) => Number(k))
                          );
                        }}
                        type="checkbox"
                      />
                      <span>{item.label}</span>
                    </label>
                  ))}
                </div>
              );
            }}
          />
        </Field>
      </div>
      <div className={classes.buttons}>
        <button type="submit">
          <FontAwesomeIcon icon={faSave} />
        </button>
      </div>
      <div className={classes.errorsWrapper}>
        <ErrorsMessage errors={errorMessages} />
      </div>
    </form>
  );
};
