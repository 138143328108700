import {
  faArrowRight,
  faBuilding,
  faLockOpen,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useCallback, useState } from "react";
import { useAuth } from "../../hooks/auth";
import { useFormValidate } from "../../hooks/form";
import ErrorsMessage from "../ErrorsMessage";
import { Field } from "../Field";

import classes from "./styles.module.scss";

interface LoginFormProps {}

export const LoginForm: FC<LoginFormProps> = () => {
  const { register, handleSubmit, errorMessages, watch } = useFormValidate(
    (yup) => ({
      email: yup.string().required().label("Email"),
      password: yup.string().required().label("Password"),
    })
  );

  const [error, setError] = useState("");

  const { login } = useAuth();

  const onSubmit = useCallback(
    async (data) => {
      const profile = await login(data.email, data.password);
      if (!profile) {
        setError("ログインＩＤまたはパスワードを確認してください。");
      }
    },
    [login]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
      <div className={classes.header}>ログイン</div>
      <div className={classes.formContent}>
        <div className={classes.formLogo}>
          <FontAwesomeIcon icon={faBuilding} />
        </div>
        <Field iconColor="#ff4040" icon={<FontAwesomeIcon icon={faUser} />}>
          <input {...register("email")} placeholder="ログインID" />
        </Field>
        <Field iconColor="#ff4040" icon={<FontAwesomeIcon icon={faLockOpen} />}>
          <input {...register("password")} type="password" placeholder="パスワード" />
        </Field>
        <div className={classes.buttons}>
          <button
            disabled={
              !!errorMessages.length ||
              !watch("email") ||
              watch("password")?.length < 6
            }
            type="submit"
          >
            <FontAwesomeIcon icon={faArrowRight} />
          </button>
        </div>
        {!!error && (
          <div>
            <ErrorsMessage errors={[error]} />
          </div>
        )}
      </div>
      <div className={classes.footer} />
    </form>
  );
};
