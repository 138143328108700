import { useCallback, useEffect, useState } from "react";
import postalCode from "jp-postalcode-lookup";
import { useApi, useCaller } from "./api";

export interface Station {
  id: number;
  name: string;
}

export interface Line {
  id: number;
  name: string;
}

interface Area {
  id: number;
  name: string;
}

interface City {
  id: number;
  name: string;
}

interface Prefecture {
  id: number;
  name: string;
}

interface Postal {
  prefectureId: number;
  prefecture: string;
  city: string;
  cityId: number;
  area: string;
  areaId: number;
  address: string;
  street?: string;
}

export function useRegion(zipCode: string) {
  const { get } = useApi("/prefectures");
  const [, caller] = useCaller();
  const [postal, setPostal] = useState<Postal>();
  const [prefecture, setPrefecture] = useState<Prefecture>();
  const [cities, setCities] = useState<City[]>([]);
  const [areas, setAreas] = useState<Area[]>([]);
  const [lines, setLines] = useState<Line[]>([]);
  const [stationsByLine, setStationsByLine] = useState<
    Record<string, Station[]>
  >({});

  const getPostal = useCallback(() => {
    if (zipCode) {
      postalCode.get(zipCode, function (address: any) {
        setPostal(address);
      });
    } else {
      setPostal(undefined);
    }
  }, [zipCode]);

  useEffect(() => {
    getPostal();
  }, [getPostal]);

  const getData = useCallback(async () => {
    setAreas([]);
    if (postal) {
      const [lines = [], cities = []] = await Promise.all([
        caller(() => get(`/${postal.prefectureId}/lines`), {
          successMessage: "",
        }),
        caller(() => get(`/${postal.prefectureId}/cities`), {
          successMessage: "",
        }),
      ]);
      setLines(lines);
      setStationsByLine(
        lines.reduce((obj: Record<string, Station[]>, line: any) => {
          obj[line.id] = line.stations;
          return obj;
        }, {})
      );
      setCities(cities);
      setPrefecture({
        id: postal.prefectureId,
        name: postal.prefecture,
      });
    } else {
      setPrefecture(undefined);
      setCities([]);
    }
  }, [postal, caller, get]);

  const getAreas = useCallback(
    async (cityId: number) => {
      if (cityId && postal) {
        const areas =
          (await caller(
            () => get(`/${postal.prefectureId}/cities/${cityId}/areas`),
            {
              successMessage: "",
            }
          )) || {};
        setAreas(areas);
      } else {
        setAreas([]);
      }
    },
    [caller, postal, get]
  );

  useEffect(() => {
    getData();
  }, [getData]);

  return {
    getAreas,
    prefecture,
    stationsByLine,
    postal,
    lines,
    cities,
    areas,
  };
}
