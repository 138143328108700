import { SpinnerRoundOutlined } from "spinners-react";
import { useLoading } from "../../hooks/loading";

import classes from "./styles.module.scss";

export interface LoadingProps {}

const Loading: React.FC<LoadingProps> = () => {
  const [loading] = useLoading();

  return loading ? (
    <div className={classes.loading}>
      <SpinnerRoundOutlined size={100} />
    </div>
  ) : null;
};

export default Loading;
