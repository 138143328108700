import { useCallback, useEffect } from "react";
import { useConnectRender } from "use-connect-render";
import { useApi, useCaller } from "./api";
import { useWebsocket } from "./websocket";

export enum ProgressStatus {
  NEW = "new",
  RUNNING = "running",
  FAIL = "fail",
  SUCCESS = "success",
}

export interface SiteByProduct extends Site {
  status: ProgressStatus;
  lastRuntime?: string;
  errors: string[];
  disabled?: boolean;
}

type OnSite = (siteByProduct: SiteByProduct) => SiteByProduct;

export function useSite() {
  const { get, post } = useApi("/products");
  const [, caller] = useCaller();
  const { pusher } = useConnectRender("site", {
    sitesByProduct: {},
  });
  const { on: onSocketMessage } = useWebsocket();

  const saveSitesByProduct = useCallback(
    (productId: string, sites: SiteByProduct[] | OnSite) => {
      pusher("sitesByProduct", (prev: Record<string, SiteByProduct[]>) => {
        if (typeof sites === "function") {
          return {
            ...prev,
            [productId]: prev[productId]?.map((site) => sites(site)),
          };
        } else {
          return {
            ...prev,
            [productId]: sites,
          };
        }
      });
    },
    [pusher]
  );

  const getByProduct = useCallback(
    async (productId: string) => {
      const sites = await caller(() => get(`/${productId}/sites`), {
        // successMessage: "Get sites product success",
        successMessage: "",
      });
      if (sites) {
        saveSitesByProduct(
          productId,
          sites.map((item: any) => ({
            ...item,
            id: item._id,
          }))
        );
      }
    },
    [get, caller, saveSitesByProduct]
  );

  const postSites = useCallback(
    async (productId: string, sitesId: string[]) => {
      const runner = await caller(
        () => post(`/${productId}/broadcast`, { sitesId }),
        {
          successMessage: "Trigger post sites success, it running now!",
        }
      );
      if (runner) {
        saveSitesByProduct(productId, (site) => {
          if (sitesId.includes(site.id)) {
            return {
              ...site,
              status: ProgressStatus.RUNNING,
            };
          }
          return site;
        });
      }
      return runner;
    },
    [post, caller, saveSitesByProduct]
  );

  useEffect(() => {
    const unsub = onSocketMessage(({ data }) => {
      if (data.type === "runner-status") {
        const { productId, siteId, status, errors } = data.data;
        saveSitesByProduct(productId, (site) => {
          if (siteId === site.id) {
            return {
              ...site,
              status,
              errors,
            };
          }
          return site;
        });
      }
    });
    return unsub;
  }, [onSocketMessage, saveSitesByProduct]);

  return {
    getByProduct,
    postSites,
  };
}
