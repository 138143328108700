import { useCallback } from "react";
import { useConnectRender } from "use-connect-render";
import { FlashOptions } from "../components/Flash";

export function useMessage() {
  const { watch } = useConnectRender("message");
  const [flashOptions, setFlashOptions] = watch<FlashOptions>("flashOptions");

  const showFlash = useCallback(
    (options: Omit<FlashOptions, "tick">) => {
      setFlashOptions({
        ...options,
        tick: `${new Date().getTime()}-${Math.random()}`,
      });
    },
    [setFlashOptions]
  );
  return {
    flashOptions,
    showFlash,
  };
}
