import { FC, useEffect, useMemo } from "react";
import { useParams } from "react-router";
import { useConnectRender } from "use-connect-render";

import { ProductForm } from "../../components/ProductForm";
import { useProduct } from "../../hooks/product";

import classes from "./styles.module.scss";

export interface FormProps {}

const Form: FC<FormProps> = () => {
  const { id } = useParams<{ id: string }>();
  const { getOne } = useProduct();
  const { listen } = useConnectRender("product");
  const [products, rooms, photos] = listen<
    [
      Record<string, Product>,
      Record<string, Room[]>,
      Record<string, ProductPhotos>
    ]
  >("products", "rooms", "photos");
  useEffect(() => {
    if (id) {
      getOne(id);
    }
  }, [getOne, id]);

  const product = useMemo(() => {
    return products[id];
  }, [id, products]);

  const photoList = useMemo(() => {
    return photos[id];
  }, [id, photos]);

  const roomList = useMemo(() => {
    return rooms[id];
  }, [id, rooms]);

  return (
    <div className={classes.page}>
      {id && product && photos ? (
        <ProductForm
          key={id}
          productId={id}
          buildingData={product.data}
          photos={photoList}
          rooms={roomList}
        />
      ) : (
        <ProductForm />
      )}
    </div>
  );
};

export default Form;
