import { FC, ReactNode, useEffect, useState } from "react";
import classes from "./styles.module.scss";

export interface TabProps {
  options: {
    disabled?: boolean;
    title: string;
    content: ReactNode;
  }[];
  defaultActive?: number;
  onChange?: (index: number) => void;
}

export const Tab: FC<TabProps> = ({ options, defaultActive = 0, onChange }) => {
  const [activedIndex, setActivedIndex] = useState(defaultActive);

  useEffect(() => {
    onChange && onChange(activedIndex);
  }, [activedIndex, onChange]);

  return (
    <div className={classes.root}>
      <div className={classes.heading}>
        {options.map((opt, index) => (
          <div
            key={index}
            data-disabled={opt.disabled}
            data-status={index === activedIndex ? "actived" : ""}
            className={classes.tabhead}
            onClick={() => setActivedIndex(index)}
          >
            {opt.title}
          </div>
        ))}
      </div>
      <div className={classes.content}>{options[activedIndex].content}</div>
    </div>
  );
};
