import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../../hooks/auth";
import classes from "./styles.module.scss";

export interface LogoutButtonProps {}

const LogoutButton: React.FC<LogoutButtonProps> = () => {
  const { userProfile, logout } = useAuth();
  return userProfile ? (
    <div className={classes.root}>
      <FontAwesomeIcon onClick={logout} icon={faSignOutAlt} />
    </div>
  ) : null;
};

export default LogoutButton;
