import { FC, useCallback, useEffect, useMemo, useRef } from "react";
import { Controller } from "react-hook-form";

import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useFormValidate } from "../../hooks/form";
import { useProduct } from "../../hooks/product";

import ErrorsMessage from "../ErrorsMessage";

import classes from "./styles.module.scss";
import { RoomsField, defaultRooms } from "./RoomsField";

export type RoomFormProps = {
  productId: string;
  rooms: Room[];
  buildingData: BuildingData;
};

export const RoomForm: FC<RoomFormProps> = ({
  productId,
  rooms,
  buildingData,
}) => {
  const refFloorData = useRef<{
    floorMax: number;
    floorUnderMax: number;
  }>({
    floorMax: buildingData.floorMax!,
    floorUnderMax: buildingData.floorUnderMax!,
  });

  useMemo(() => {
    refFloorData.current.floorMax = buildingData.floorMax!;
    refFloorData.current.floorUnderMax = buildingData.floorUnderMax!;
  }, [buildingData]);

  const { getPreviousValues } = useFormValidate((yup) => ({}));

  const dfRooms =
    getPreviousValues("rooms-form")?.rooms ||
    (rooms.length ? rooms : defaultRooms);

  const { watch, control, handleSubmit, errorMessages, cachePreviousValues } =
    useFormValidate(
      (yup) => {
        return {
          rooms: yup.array().of(
            yup.object().shape({
              _id: yup.string(),
              name: yup.string().required().label("部屋名"),
              contractType: yup.trueNumber().required().label("借家契約"),
              contractYear: yup
                .trueNumber()
                .min(0)
                .label("契約年")
                .when("contractType", {
                  is: 2,
                  then: yup.trueNumber().min(0).label("契約年").required(),
                }),
              contractNote: yup.string().label("契約備考"),
              usage: yup.trueNumber().required().label("用途"),
              arrange: yup.trueNumber().required().label("間取り"),
              occupiedArea: yup
                .trueNumber()
                .min(0)
                .required()
                .label("占有面積"),
              rentPrice: yup.trueNumber().min(0).required().label("家賃"),
              managementFee: yup
                .trueNumber()
                .min(0)
                .required()
                .label("管理費・共益費"),
              consumptionTax: yup.trueNumber().required().label("消費税表示"),
              securityDeposit: yup
                .object()
                .shape({
                  value: yup.trueNumber().min(0).required().label("敷金"),
                  unit: yup.trueNumber().required().label("敷金単位"),
                })
                .required(),
              keyMoney: yup
                .object()
                .shape({
                  value: yup.trueNumber().min(0).required().label("礼金"),
                  unit: yup.trueNumber().required().label("礼金単位"),
                })
                .required(),
              deposit: yup
                .object()
                .shape({
                  value: yup.trueNumber().min(0).required().label("保証金"),
                  unit: yup.trueNumber().required().label("保証金単位"),
                })
                .required(),
              depreciation: yup.object().shape({
                value: yup.trueNumber().min(0).label("償却金"),
                unit: yup.trueNumber().label("償却金単位"),
              }),
              floorNumber: yup
                .trueNumber()
                .required()
                .min(0)
                .label("部屋の階部分")
                .when("floorType", (value: number, schema: any) => {
                  return value === 1
                    ? schema.max(refFloorData.current.floorMax!)
                    : schema.max(refFloorData.current.floorUnderMax!);
                }),
              floorType: yup.trueNumber().required().label("地上 / 地下"),
              availableDate: yup.object().shape({
                type: yup.trueNumber().required().label("入居可能日"),
                year: yup
                  .trueNumber()
                  .label("入居可能年")
                  .when("type", {
                    is: 2,
                    then: yup.trueNumber().label("入居可能年").required(),
                  }),
                month: yup
                  .trueNumber()
                  .label("入居可能月")
                  .when("type", {
                    is: 2,
                    then: yup.trueNumber().label("入居可能月").required(),
                  }),
                season: yup
                  .trueNumber()
                  .label("入居可能時期")
                  .when("type", {
                    is: 2,
                    then: yup.trueNumber().label("入居可能時期").required(),
                  }),
                auto: yup.boolean().label("即入居可自動切替"),
              }),
              prTitle: yup.string().required().label("PRコメントのタイトル"),
              prComment: yup
                .string()
                .required()
                .label("この物件のおすすめコメント"),
              phoneNumber: yup.string().label("電話番号"),
              positionArticleItems: yup.array().of(yup.number()).label("位置"),
              conditionArticleItems: yup.array().of(yup.number()).label("条件"),
              kitchenArticleItems: yup
                .array()
                .of(yup.number())
                .label("キッチン"),
              bathArticleItems: yup
                .array()
                .of(yup.number())
                .label("バス・トイレ・洗面"),
              airConditionArticleItems: yup
                .array()
                .of(yup.number())
                .label("冷暖房"),
              servicesArticleItems: yup
                .array()
                .of(yup.number())
                .label("設備・サービス"),
              communicationArticleItems: yup
                .array()
                .of(yup.number())
                .label("放送・通信:"),
              generalArticleItems: yup.array().of(yup.number()).label("その他"),
              postId: yup.string().label("エル目白更新用 投稿ID"),
              boshuuchuu: yup.string().required().label("募集中"),
            })
          ),
        };
      },
      {
        defaultValues: {
          rooms: dfRooms,
        },
      }
    );

  useEffect(() => {
    return () => {
      cachePreviousValues("rooms-form");
    };
  }, [cachePreviousValues]);

  const { saveRooms } = useProduct();

  const existRooms: Room[] = watch("rooms");

  const onSubmit = useCallback(
    (data) => {
      console.log("data", data);
      saveRooms(
        productId,
        data.rooms.map((item: any) => {
          delete item.id;
          delete item.availableDate._id;
          delete item.__v;
          delete item.productId;
          return item;
        })
      );
    },
    [saveRooms, productId]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.generalSection}>
      <div className={classes.heading}>
        <div className={classes.title}>
          部屋 {existRooms.length > 1 ? `(${existRooms.length})` : ""}
        </div>
      </div>
      <div className={classes.rooms}>
        <Controller
          name={"rooms"}
          defaultValue={dfRooms}
          control={control}
          render={({ field: { onChange, value } }) => {
            return <RoomsField value={value} onChange={onChange} />;
          }}
        />
      </div>

      <div className={classes.buttons}>
        <button type="submit">
          <FontAwesomeIcon icon={faSave} />
        </button>
      </div>
      <div className={classes.errorsWrapper}>
        <ErrorsMessage errors={errorMessages} />
      </div>
    </form>
  );
};
