import { useCallback } from "react";
import { useConnectRender } from "use-connect-render";
import { useCaller } from "./api";

const MOCK_EMAIL = "fGiwwWjfi7y87-7";
const MOCK_PASSWORD = "qvHkrmYr4h";

async function mockGetProfile(token: string): Promise<UserProfile | undefined> {
  return new Promise((res) => {
    setTimeout(() => {
      if (token === "fake-token") {
        res({
          id: "xxx",
          email: MOCK_EMAIL,
          name: "Admin",
        });
      } else {
        res(undefined);
      }
    }, 1000);
  });
}

async function mockLogin(
  email: string,
  password: string
): Promise<string | undefined> {
  return new Promise((res) => {
    setTimeout(() => {
      if (email === MOCK_EMAIL && password === MOCK_PASSWORD) {
        res("fake-token");
      } else {
        res(undefined);
      }
    }, 1000);
  });
}

export function useAuth() {
  const [, caller] = useCaller();
  const { watch } = useConnectRender("auth");
  const [userProfile, setUserProfile] =
    watch<UserProfile | undefined>("userProfile");

  const getProfile = useCallback(async () => {
    const token = localStorage.getItem("token");
    if (token) {
      const profile = await mockGetProfile(token);
      setUserProfile(profile);
      return profile;
    }
    return null;
  }, [setUserProfile]);

  const login = useCallback(
    async (email: string, password: string) => {
      const profile = await caller(async () => {
        const token = await mockLogin(email, password);
        if (token) {
          localStorage.setItem("token", token);
          return mockGetProfile(token);
        }
        return null;
      });
      setUserProfile(profile);
      return profile;
    },
    [caller, setUserProfile]
  );

  const logout = useCallback(() => {
    localStorage.removeItem("token");
    setUserProfile(undefined);
  }, [setUserProfile]);

  return {
    login,
    logout,
    getProfile,
    userProfile,
  };
}
