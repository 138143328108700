import { useCallback, useMemo } from "react";
import SquareDiv from "react-square-div";
import { Control, Controller } from "react-hook-form";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";

import { Photo } from "../Photo";

import classes from "./styles.module.scss";

type Value = {
  id?: string;
  type?: string;
  comment?: string;
  file: File | string;
  group?: string;
  uiID: string;
}[];

export type SingleValue = Value[0];

type PHOTO_TYPE = "appearance" | "neighborhood" | "floor" | "general";

interface InputProps {
  value: Value;
  onChange: (item: Value) => void;
  onSave?: (item: SingleValue) => void;
  onRemove?: (item: SingleValue) => void;
  accepts?: PHOTO_TYPE[];
  onDisbaled?: (item: typeof PHOTO_TYPES[0]) => boolean;
  defaultType?: SingleValue["type"];
}

const PHOTO_TYPES = [
  {
    type: "appearance",
    label: "外観",
  },
  {
    type: "neighborhood",
    label: "周辺",
  },
  {
    type: "floor",
    label: "間取",
  },
  {
    type: "general",
    label: "その他画像",
  },
];

const Input: React.FC<InputProps> = ({
  defaultType,
  onDisbaled = () => false,
  accepts = ["appearance", "neighborhood", "floor", "general"],
  value,
  onChange,
  onSave,
  onRemove,
}) => {
  const whitelist = useMemo(() => {
    return accepts.reduce(
      (hs, item) => ({ ...hs, [item]: true }),
      {}
    ) as Record<string, boolean>;
  }, [accepts]);

  const handleUploaded = useCallback(
    async ({ target }) => {
      const items = (Object.values(target.files) as File[]).map((file) => ({
        type: defaultType,
        file,
        uiID: `${Math.random()}${new Date().getTime()}`,
      }));
      onChange([...value, ...items]);
      target.value = "";
      if (defaultType && onSave) {
        for (const item of items) {
          await onSave(item);
        }
      }
    },
    [onChange, onSave, defaultType, value]
  );

  const handleDeleteByIndex = useCallback(
    (index) => {
      onRemove && onRemove(value[index]);
      onChange(value.filter((_, i) => i !== index));
    },
    [onChange, onRemove, value]
  );

  const handleDelete = useCallback(
    (index) => {
      return () => handleDeleteByIndex(index);
    },
    [handleDeleteByIndex]
  );

  return (
    <div className={classes.root}>
      <div className={classes.listUploaded}>
        {value.map(({ file, type, comment, uiID }, i) => (
          <div key={i} className={classes.uploaded}>
            <SquareDiv>
              <div className={classes.pic}>
                <div className={classes.thumbnail}>
                  <Photo src={file} />
                </div>
                <FontAwesomeIcon onClick={handleDelete(i)} icon={faTimes} />
              </div>
            </SquareDiv>
            <div className={classes.type}>
              <div className={classes.star}>*</div>
              <select
                key={(uiID || "") + i}
                value={type}
                onChange={({ target }) => {
                  const newValue = value.map((item, j) =>
                    i === j ? { ...item, type: target.value } : item
                  );
                  onChange(newValue);
                  const item = newValue.find((_, j) => j === i)!;
                  if (item.type) {
                    onSave && onSave(item);
                  }
                }}
              >
                <option value="">--種別を選択してください。--</option>
                {PHOTO_TYPES.filter((item) => !!whitelist[item.type]).map(
                  (item) => (
                    <option
                      disabled={onDisbaled(item)}
                      key={item.type}
                      value={item.type}
                    >
                      {item.label}
                    </option>
                  )
                )}
              </select>
            </div>
            <textarea
              key={(uiID || "") + i}
              value={comment || ""}
              onChange={({ target }) =>
                onChange(
                  value.map((item, j) =>
                    i === j
                      ? {
                          ...item,
                          comment: target.value,
                          prevComment: item.comment,
                        }
                      : item
                  )
                )
              }
              onBlur={() => {
                const item = { ...value.find((_, j) => j === i)! };
                if (item.type) {
                  const extraItem = item as SingleValue & {
                    prevComment?: string;
                  };
                  if (
                    extraItem.prevComment != null &&
                    extraItem.prevComment !== item.comment
                  ) {
                    extraItem.prevComment = item.comment;
                    onSave && onSave(item);
                  }
                }
              }}
              className={classes.comment}
              placeholder="コメント"
            />
          </div>
        ))}

        <div className={classes.uploaded}>
          <label>
            <input
              multiple
              onChange={handleUploaded}
              accept="image/png, image/jpeg"
              type="file"
            />
            <div className={classes.upBox}>
              <FontAwesomeIcon icon={faPlus} />
            </div>
          </label>
        </div>
      </div>
    </div>
  );
};

interface PhotoUploadProps extends Omit<InputProps, "value" | "onChange"> {
  name: string;
  control: Control;
}

export const PhotoUpload: React.FC<PhotoUploadProps> = ({
  defaultType,
  onDisbaled,
  accepts,
  name,
  control,
  onSave,
  onRemove,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={[]}
      render={({ field: { value, onChange } }) => (
        <Input
          defaultType={defaultType}
          onDisbaled={onDisbaled}
          accepts={accepts}
          onSave={onSave}
          onRemove={onRemove}
          onChange={onChange}
          value={value}
        />
      )}
    />
  );
};
