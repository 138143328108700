import { useCallback, useMemo } from "react";
import { useEvent, useGlobal } from "use-connect-render";

export function useWebsocket() {
  const global = useGlobal<{
    ws?: WebSocket;
  }>({});

  const [, addListener, emit] = useEvent<{}>({
    name: "web-socket",
    initialData: {},
  });

  const ws = useMemo(() => {
    if (!global.ws) {
      const protcol = window.location.protocol === "https:" ? "wss" : "ws";
      const ws = new WebSocket(
        `${protcol}://${process.env.REACT_APP_SOCKET_SERVER}/${protcol}?token=${
          localStorage.getItem("token") || ""
        }`
      );

      ws.onopen = () => {
        console.log("onopen");
      };

      ws.onclose = async () => {
        console.log("onclose");
        global.ws?.close();
        global.ws = undefined;
      };

      ws.onmessage = ({ data }: any) => {
        const obj = JSON.parse(data);
        console.log("obj", obj);
        emit("on-socket-message", obj);
      };

      window.onclose = function () {
        global.ws?.close();
      };
      global.ws = ws;
    }
    return global.ws;
  }, [global, emit]);

  const on = useCallback(
    (func: (data: Record<string, any>) => void) => {
      return addListener("on-socket-message", func);
    },
    [addListener]
  );

  return {
    ws,
    on,
  };
}
