import { FC } from "react";
import { Tab } from "../Tab";

import classes from "./styles.module.scss";
import { PhotoForm } from "./PhotoForm";
import { BuildingForm } from "./BuidingForm";
import { RoomForm } from "./RoomForm";
import { useClearFormCache } from "../../hooks/form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

interface ProductFormProps {
  productId?: string;
  buildingData?: BuildingData;
  photos?: ProductPhotos;
  rooms?: Room[];
}

export const ProductForm: FC<ProductFormProps> = ({
  productId,
  buildingData,
  photos,
  rooms,
}) => {
  useClearFormCache();

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Link to="/">
          <FontAwesomeIcon icon={faArrowLeft} />
        </Link>
        入力フォーム
      </div>
      <Tab
        options={[
          {
            title: "一般",
            content: (
              <div className={classes.formTab}>
                <div className={classes.generalSections}>
                  <BuildingForm
                    key={+!!buildingData}
                    productId={productId}
                    buildingData={buildingData}
                  />
                  {buildingData && rooms && (
                    <RoomForm
                      buildingData={buildingData}
                      rooms={rooms!}
                      productId={productId!}
                    />
                  )}
                </div>
              </div>
            ),
          },
          {
            title: "画像",
            disabled: !buildingData,
            content: buildingData && (
              <PhotoForm photos={photos!} productId={productId!} />
            ),
          },
          // {
          //   title: "設備",
          //   disabled: !buildingData,
          //   content: "33",
          // },
        ]}
      />
      <div className={classes.footer}>
        <Link to="/">
          <FontAwesomeIcon icon={faArrowLeft} />
        </Link>
      </div>
    </div>
  );
};
