import React, { FC, HTMLAttributes } from "react";

import classes from "./styles.module.scss";

interface Column<D = any> {
  key?: string;
  code?: string;
  title: React.ReactNode;
  className?: string;
  render?: (value: string, record: D) => React.ReactNode;
  getAttrs?: (
    value: string,
    record: D
  ) => HTMLAttributes<HTMLElement> &
    Record<string, string | boolean | undefined>;
}

export interface TableProps<D = any> {
  addons?: React.ReactElement[];
  columns: Column[];
  dataKey?: string;
  data: D[];
}

const Table: FC<TableProps> = ({
  addons = [],
  dataKey = "id",
  columns,
  data,
}) => {
  return (
    <div className={classes.table}>
      {!!addons.length && (
        <div className={classes.addons}>
          {addons.map((addon, i) => (
            <React.Fragment key={i}>{addon}</React.Fragment>
          ))}
        </div>
      )}
      <div className={classes.wrap}>
        <table>
          <thead>
            <tr>
              {columns.map((col, i) => (
                <th key={col.key || i}>{col.title}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((item, i) => (
              <tr key={item[dataKey] || i}>
                {columns.map(
                  ({ key, render, code, className, getAttrs }, c) => {
                    return (
                      <td
                        key={key || c}
                        className={className}
                        {...(getAttrs ? getAttrs(item[code!], item) : {})}
                      >
                        {render ? render(item[code!], item) : item[code!]}
                      </td>
                    );
                  }
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;
