import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Home from "./pages/Home";
import Form from "./pages/Form";
import Login from "./pages/Login";
import Middleware, { MiddlewareMode } from "./pages/Middleware";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";

import Loading from "./components/Loading";
import LogoutButton from "./components/LogoutButton";
import MessagePopup from "./components/MessagePopup";

import "react-square-div/lib/styles.css";
import "react-toastify/dist/ReactToastify.css";

library.add(fab, fas, far);

function App() {
  return (
    <div className="App">
      <MessagePopup />
      <Loading />
      <LogoutButton />
      <Router>
        <Switch>
          <Route
            exact
            path="/form/:id?"
            render={() => (
              <Middleware>
                <Form />
              </Middleware>
            )}
          />
          <Route
            exact
            path="/home"
            render={() => (
              <Middleware>
                <Home />
              </Middleware>
            )}
          />
          <Route
            exact
            path="/login"
            render={() => (
              <Middleware mode={MiddlewareMode.PUBLIC}>
                <Login />
              </Middleware>
            )}
          />
          <Route
            path="/"
            render={() => (
              <Middleware>
                <Home />
              </Middleware>
            )}
          />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
