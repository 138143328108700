import { useMessage } from "../../hooks/message";
import Flash from "../Flash";

export interface MessagePopupProps {}

const MessagePopup: React.FC<MessagePopupProps> = () => {
  const { flashOptions } = useMessage();
  return flashOptions ? <Flash {...flashOptions} /> : null;
};

export default MessagePopup;
