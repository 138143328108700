import { useCallback, useMemo } from "react";
import useFetch, { UseFetchArgs, UseFetch, CachePolicies } from "use-http";
import { useLoading } from "./loading";
import { useMessage } from "./message";

export function useApi<TData = any>(
  path: string,
  ...args: UseFetchArgs
): UseFetch<TData> {
  const url = useMemo(() => {
    return path.startsWith("http")
      ? path
      : `${process.env.REACT_APP_API}${path}`;
  }, [path]);

  const _args = [url, ...args] as any[];
  return useFetch(..._args, {
    cachePolicy: CachePolicies.NETWORK_ONLY,
  });
}

interface CallerOptions {
  successMessage?: string;
  errorMessage?: string;
}

type Caller = (
  func: () => Promise<any>,
  options?: CallerOptions
) => Promise<any>;

export function useCaller() {
  const [loading, setLoading] = useLoading();
  const { showFlash } = useMessage();

  const caller = useCallback<Caller>(
    async (
      func,
      {
        successMessage = "Execute|Loading data success",
        errorMessage = "Have some error, please try again",
      } = {}
    ) => {
      setLoading(true);
      try {
        const res = await func();
        setLoading(false);
        if (res == null || res.error || res.statusCode >= 400) {
          if (errorMessage !== "") {
            showFlash({
              type: "error",
              duration: 2000,
              message: errorMessage,
            });
          }
          return null;
        }
        if (successMessage !== "") {
          showFlash({
            type: "success",
            duration: 2000,
            message: successMessage,
          });
        }
        return res;
      } catch (error) {
        console.error(error);
        setLoading(false);
        if (errorMessage !== "") {
          showFlash({
            type: "error",
            duration: 2000,
            message: errorMessage,
          });
        }
        return null;
      }
    },
    [setLoading, showFlash]
  );
  return [loading, caller] as [boolean, Caller];
}
