export const dateHelper = (function () {
  const now = new Date();
  const daysByMoth: Record<number, number> = {
    1: 31,
    2: -1,
    3: 31,
    4: 30,
    5: 31,
    6: 30,
    7: 31,
    8: 31,
    9: 30,
    10: 31,
    11: 30,
    12: 31,
  };
  return {
    currentYear: now.getFullYear(),
    getDays: (year: number, month: number) => {
      let dayNumber = daysByMoth[month];
      if (dayNumber === -1) {
        dayNumber = year % 400 === 0 || year % 4 === 0 ? 29 : 28;
      }
      return Array.from({ length: dayNumber }).map((_, i) => i + 1);
    },
  };
})();

export function isEmpty(value: any) {
  return value === "" || value == null;
}

export function isNumberAble(value: any) {
  return (
    typeof value === "number" ||
    (!isEmpty(value) && !isNaN(value) && !value.endsWith("."))
  );
}

function countChar(text: string, char: string) {
  return (text.match(new RegExp(char, "g")) || []).length;
}

export function numberTransformer(value: any) {
  return isNumberAble(value) ? Number(value) : value;
}

export function getNumeric(value: string, integer?: boolean) {
  const p = /-?\.*\d*/g;
  let v = value.match(p)?.join("") || "";
  if (integer) {
    v = v.split(".").join("");
  }
  v = v.length === 1 ? v : v.replace(/-$/, "");
  v =
    v.includes("-") && countChar(v, "-") !== 1
      ? `${v.substring(0, v.indexOf("-") + 1)}${v
          .substr(v.indexOf("-") + 1, v.length - 1)
          .split("-")
          .join("")}`
      : v;
  v =
    v.includes(".") && countChar(v, "\\.") !== 1
      ? `${v.substring(0, v.indexOf(".") + 1)}${v
          .substr(v.indexOf(".") + 1, v.length - 1)
          .split(".")
          .join("")}`
      : v;
  return numberTransformer(v);
}
