import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  useMemo,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import * as ReactDOM from "react-dom";

import classes from "./styles.module.scss";

type ModalProps = {
  title?: string;
  children: React.ReactNode;
  width?: number | string;
  maxHeight?: number | string;
  isOpen: boolean;
  onClose: () => void;
  top?: string | number;
};

export default function Modal({
  title,
  top,
  isOpen,
  onClose = () => {},
  children,
  maxHeight = 300,
  width = 400,
}: ModalProps) {
  const wraperRef = useRef<HTMLDivElement>(null);
  const refContent = useRef<HTMLDivElement>(null);

  const [isFade, setFade] = useState(false);

  const container = useMemo(() => {
    const c = document.createElement("div");
    c.style.zIndex = "4000";
    c.style.position = "fixed";
    c.style.top = "0";
    c.style.left = "0";
    return c;
  }, []);

  useEffect(() => {
    document.body.appendChild(container);
    return () => {
      document.body.removeChild(container);
    };
  }, [container]);

  const handleClose = useCallback(() => {
    setFade(false);
    setTimeout(() => {
      onClose();
    }, 210);
  }, [onClose]);

  const injectActions = useCallback(() => {
    const closeBtns = [
      ...(new Set(
        refContent.current!.querySelectorAll('[data-role="close"]')
      ) as any),
    ] as HTMLElement[];

    const onCloseClick = (e: any) => {
      e.preventDefault();
      handleClose();
    };

    for (const closeBtn of closeBtns) {
      closeBtn.addEventListener("click", onCloseClick);
    }

    return () => {
      for (const closeBtn of closeBtns) {
        closeBtn.removeEventListener("click", onCloseClick);
      }
    };
  }, [handleClose]);

  useEffect(() => {
    if (isOpen) {
      setFade(true);
      const unsubcribe = injectActions();
      return unsubcribe;
    }
  }, [isOpen, injectActions]);

  return (
    <>
      {ReactDOM.createPortal(
        isOpen ? (
          <div
            className={[classes.root, top == null ? classes.center : ""].join(
              " "
            )}
          >
            <div className={classes.closeBackground} onClick={handleClose} />
            <div
              ref={wraperRef}
              data-fade={isFade ? "visible" : ""}
              className={classes.wraper}
              style={
                {
                  maxHeight,
                  maxWidth: width,
                  top,
                } as React.CSSProperties
              }
            >
              <div className={classes.header}>
                <div className={classes.title}>{title}</div>
                <FontAwesomeIcon
                  onClick={handleClose}
                  className={classes.closeButton}
                  icon={faTimes}
                />
              </div>
              <div ref={refContent} className={classes.content}>
                {children}
              </div>
            </div>
          </div>
        ) : null,
        container
      )}
    </>
  );
}
