import { useEffect } from "react";

import { ToastContainer, ToastContainerProps, toast } from "react-toastify";

export type FlashType = "info" | "success" | "warn" | "error" | "dark";

export interface FlashOptions {
  type?: FlashType;
  message: string;
  tick?: string | number;
  duration?: number;
}

type FlashProps = ToastContainerProps & FlashOptions;

export default function Flash({
  tick,
  type,
  duration = 3000,
  message,
  ...rest
}: FlashProps) {
  useEffect(() => {
    if (type) {
      toast[type](message);
    } else {
      toast(message);
    }
  }, [tick, type, message]);

  return (
    <ToastContainer
      position="top-right"
      autoClose={duration}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      {...rest}
    />
  );
}
