import { useCallback, useEffect } from "react";
import { useConnectRender, useGlobal } from "use-connect-render";
import { useApi, useCaller } from "./api";
import { useWebsocket } from "./websocket";

export function useProcess() {
  const { get } = useApi("/process-status");
  const [, caller] = useCaller();
  const { watch } = useConnectRender("process", {
    isRunning: false,
  });
  const { on: onSocketMessage } = useWebsocket();

  const global = useGlobal<{ isFetchedProcessStatus?: boolean }>();

  const [isRunning, setIsRunning] = watch("isRunning");

  const getProcessStatus = useCallback(async () => {
    if (global.isFetchedProcessStatus) {
      return;
    }
    global.isFetchedProcessStatus = true;
    const status = await caller(() => get("/"), { successMessage: "" });
    if (status != null) {
      setIsRunning(status);
    } else {
      global.isFetchedProcessStatus = false;
    }
  }, [global, setIsRunning, caller, get]);

  useEffect(() => {
    getProcessStatus();
  }, [getProcessStatus]);

  useEffect(() => {
    const unsub = onSocketMessage(({ data }) => {
      if (data.type === "process") {
        const { isRunning } = data.data;
        setIsRunning(isRunning);
      }
    });
    return unsub;
  }, [onSocketMessage, setIsRunning]);

  return {
    isRunning: isRunning,
    getProcessStatus,
  };
}
