import { useCallback } from "react";
import { useConnectRender } from "use-connect-render";

export function useLoading() {
  const { watch } = useConnectRender("globalLoading");

  const [isVisible, setVisible] = watch("isVisible");

  const setLoading = useCallback(
    (loading?: boolean) => {
      setVisible(loading);
    },
    [setVisible]
  );

  return [isVisible, setLoading] as [boolean, (loading?: boolean) => void];
}
