import { FC, useCallback, useEffect, useMemo, useRef } from "react";

import { PhotoGroup } from "../../enums";
import { useFormValidate } from "../../hooks/form";
import { useProduct } from "../../hooks/product";

import { PhotoUpload, SingleValue } from "../PhotoUpload";

import classes from "./styles.module.scss";

export interface PhotoFormProps {
  productId: string;
  photos: ProductPhotos;
}
export const PhotoForm: FC<PhotoFormProps> = ({ productId, photos }) => {
  const refInstance = useRef<{
    photosBK: ProductPhotos;
  }>({
    photosBK: {
      buildings: [],
      rooms: [],
    },
  });
  const { savePhoto, removePhoto } = useProduct();
  const {
    watch,
    cachePreviousValues,
    bindFromPreviousValues,
    control,
    handleSubmit,
    setValue,
    getValues,
  } = useFormValidate((yup) => {
    return {
      buildingPhotos: yup
        .array()
        .of(
          yup.object().shape({
            uiID: yup.string().required(),
            id: yup.string().label("Building photo id"),
            type: yup.string().required().label("Building photo type"),
            comment: yup.string().label("Building photo comment"),
            file: yup.file({
              label: "Building photo",
              size: 5,
            }),
          })
        )
        .label("Building photo"),
      roomPhotos: yup.array().of(
        yup.object().shape({
          type: yup.string().required().label("Room photo type"),
          comment: yup.string().label("Room photo comment"),
          file: yup.file({
            label: "Room photo",
            size: 5,
          }),
        })
      ),
    };
  });

  useEffect(() => {
    return () => {
      cachePreviousValues("photo-form");
    };
  }, [cachePreviousValues]);

  useEffect(() => {
    bindFromPreviousValues("photo-form", () => {
      setValue("buildingPhotos", []);
      setValue("roomPhotos", []);
      if (refInstance.current.photosBK.buildings) {
        setValue("buildingPhotos", [
          ...photos!.buildings,
          ...refInstance.current.photosBK.buildings,
        ]);
        refInstance.current.photosBK.buildings = [];
      } else {
        setValue("buildingPhotos", [...photos!.buildings]);
      }
      if (refInstance.current.photosBK.rooms) {
        setValue("roomPhotos", [
          ...photos!.rooms,
          ...refInstance.current.photosBK.rooms,
        ]);
        refInstance.current.photosBK.rooms = [];
      } else {
        setValue("roomPhotos", [...photos!.rooms]);
      }
    });
  }, [bindFromPreviousValues, setValue, photos]);

  const handleSavePhoto = useCallback(
    async (group: string, item: SingleValue) => {
      let isFail;
      refInstance.current.photosBK = {
        buildings: getValues("buildingPhotos").filter(
          (pt: SingleValue) => item.uiID !== pt.uiID && !pt.id
        ),
        rooms: getValues("roomPhotos").filter(
          (pt: SingleValue) => item.uiID !== pt.uiID && !pt.id
        ),
      };
      if (!item.id) {
        // add photo
        const formData = new FormData();
        formData.append("file", item.file);
        formData.append("type", item.type!);
        formData.append("comment", item.comment || "");
        formData.append("group", group);
        isFail = !(await savePhoto(productId, formData));
      } else {
        isFail = !(await savePhoto(productId, {
          id: item.id!,
          file: item.file as string,
          type: item.type!,
          comment: item.comment,
          group,
        }));
      }

      if (isFail) {
        refInstance.current.photosBK.buildings = [];
        refInstance.current.photosBK.rooms = [];
      }
    },
    [productId, getValues, savePhoto]
  );

  const handleSaveBuildingPhoto = useCallback(
    (item: SingleValue) => handleSavePhoto(PhotoGroup.BUILDING, item),
    [handleSavePhoto]
  );

  const handleSaveRoomPhoto = useCallback(
    (item: SingleValue) => handleSavePhoto(PhotoGroup.ROOM, item),
    [handleSavePhoto]
  );

  const handleSaveRemovePhoto = useCallback(
    async (item: SingleValue) => {
      let isFail;
      refInstance.current.photosBK = {
        buildings: getValues("buildingPhotos").filter(
          (pt: SingleValue) => item.uiID !== pt.uiID && !pt.id
        ),
        rooms: getValues("roomPhotos").filter(
          (pt: SingleValue) => item.uiID !== pt.uiID && !pt.id
        ),
      };
      isFail = !(await removePhoto(
        productId,
        item.group as PhotoGroup,
        item.id!
      ));
      if (isFail) {
        refInstance.current.photosBK.buildings = [];
        refInstance.current.photosBK.rooms = [];
      }
    },
    [productId, getValues, removePhoto]
  );

  const onSubmit = useCallback(() => {}, []);

  const buildingPhotos: Photo[] = watch("buildingPhotos", []);
  const roomPhotos: Photo[] = watch("roomPhotos", []);

  const isMaxAppearance = useMemo(() => {
    return (
      buildingPhotos.filter((photo) => photo.type === "appearance").length >= 2
    );
  }, [buildingPhotos]);

  const isMaxFloor = useMemo(() => {
    return roomPhotos.some((photo) => photo.type === "floor");
  }, [roomPhotos]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.formTab}>
      <div className={classes.typesSection}>
        <div className={classes.title}>建物画像</div>
        <div className={classes.uploadWrapper}>
          <PhotoUpload
            onDisbaled={(option) =>
              option.type === "appearance" && isMaxAppearance
            }
            defaultType="neighborhood"
            accepts={["appearance", "neighborhood"]}
            onSave={handleSaveBuildingPhoto}
            onRemove={handleSaveRemovePhoto}
            name="buildingPhotos"
            control={control}
          />
        </div>
      </div>
      <div className={classes.typesSection}>
        <div className={classes.title}>部屋画像</div>
        <div className={classes.uploadWrapper}>
          <PhotoUpload
            onDisbaled={(option) => option.type === "floor" && isMaxFloor}
            defaultType="general"
            accepts={["floor", "general"]}
            onSave={handleSaveRoomPhoto}
            onRemove={handleSaveRemovePhoto}
            name="roomPhotos"
            control={control}
          />
        </div>
      </div>
    </form>
  );
};
