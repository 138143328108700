import { FC, useCallback } from "react";

import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import set from "lodash/set";
import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";

import { dateHelper, isEmpty, numberTransformer } from "../../utils";
import {
  availableDateSeason,
  positionArticleItems,
  conditionArticleItems,
  kitchenArticleItems,
  bathArticleItems,
  airConditionArticleItems,
  servicesArticleItems,
  communicationArticleItems,
  generalArticleItems,
  boshuuchuus,
} from "../../options";

import { Field } from "../Field";
import { NumericInput } from "../NumericInput";

import classes from "./styles.module.scss";

export interface RoomsFieldProps {
  value: Room[];
  onChange: (value: Room[]) => void;
}

interface ArticleItemsProps {
  value: number[];
  onChange: (value: number[]) => void;
  options: { label: string; value: number }[];
}

const ArticleItems = ({ value, onChange, options }: ArticleItemsProps) => {
  const hash = value.reduce<Record<number, boolean>>(
    (hs, item) => ({ ...hs, [item]: true }),
    {}
  );
  return (
    <div data-wrap="true" data-role="field-group">
      {options.map((item) => (
        <label key={item.value}>
          <input
            value={item.value}
            checked={!!hash[item.value]}
            onChange={({ target }) => {
              hash[item.value] = target.checked;
              onChange(
                Object.keys(hash)
                  .filter((k: any) => hash[k])
                  .map((k) => Number(k))
              );
            }}
            type="checkbox"
          />
          <span>{item.label}</span>
        </label>
      ))}
    </div>
  );
};

export const defaultRooms = [
  {
    availableDate: {
      type: 0,
    },
    contractType: 1,
    usage: 1,
    consumptionTax: 2,
    securityDeposit: {
      unit: 1,
    },
    keyMoney: {
      unit: 1,
    },
    deposit: {
      unit: 1,
    },
    depreciation: {
      unit: 1,
    },
    floorType: 1,
    positionArticleItems: [],
    conditionArticleItems: [],
    kitchenArticleItems: [],
    bathArticleItems: [],
    airConditionArticleItems: [],
    servicesArticleItems: [],
    communicationArticleItems: [],
    generalArticleItems: [],
    boshuuchuu: "募集中",
  },
];

const MAX_ROOM = 5;
export const RoomsField: FC<RoomsFieldProps> = ({ value, onChange }) => {
  const handleAddRoom = useCallback(() => {
    if (value.length < MAX_ROOM) {
      onChange([...value, cloneDeep(defaultRooms[0]) as any as Room]);
    }
  }, [value, onChange]);

  const handleRemoveRoom = useCallback(() => {
    if (value.length > 1) {
      onChange(value.slice(0, value.length - 1));
    }
  }, [value, onChange]);

  const handleInput = useCallback(
    (
      room,
      path,
      index,
      transform = (value: string) => value,
      valuer = (e: any) => e.target.value
    ) => {
      const item = get(room, path);
      return {
        value: item == null ? "" : item,
        onChange: (e: any) =>
          onChange(
            value.map((item, i) => {
              if (i === index) {
                const vl = set(item, path, transform(valuer(e)));
                return !isEmpty(vl) &&
                  typeof vl === "object" &&
                  !Array.isArray(vl)
                  ? { ...vl }
                  : vl;
              }
              return item;
            })
          ),
      };
    },
    [onChange, value]
  );

  return (
    <>
      {value.map((room: any, i: number) => {
        return (
          <div key={i} className={classes.room}>
            <div className={classes.divider}>
              <div />
              <div>部屋 {i + 1}</div>
              <div />
              <div className={classes.addons}>
                <div
                  className={classes.addon}
                  data-mode="primary"
                  aria-disabled={
                    i !== value.length - 1 || value.length >= MAX_ROOM
                  }
                  onClick={handleAddRoom}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </div>
                <div
                  className={classes.addon}
                  data-mode="danger"
                  aria-disabled={i !== value.length - 1 || value.length <= 1}
                  onClick={handleRemoveRoom}
                >
                  <FontAwesomeIcon icon={faMinus} />
                </div>
              </div>
              <div />
            </div>
            <div className={classes.fields}>
              <Field required label="部屋名">
                <input {...handleInput(room, "name", i)} />
              </Field>

              <Field required label="借家契約">
                <select
                  {...handleInput(room, "contractType", i, numberTransformer)}
                >
                  <option value="1">普通借家契約</option>
                  <option value="2">定期借家契約</option>
                </select>
                <NumericInput
                  integer
                  min={0}
                  placeholder="年"
                  {...handleInput(room, "contractYear", i, numberTransformer)}
                />
                <input
                  placeholder="契約備考"
                  {...handleInput(room, "contractNote", i)}
                />
              </Field>
              <Field required label="用途">
                <select {...handleInput(room, "usage", i, numberTransformer)}>
                  <option value="1">住居専用</option>
                  <option value="2">事務所・店舗専用</option>
                  <option value="3">住居・事務所・店舗利用可</option>
                  <option value="4">住居・事務所利用可</option>
                  <option value="5">工場・倉庫</option>
                </select>
              </Field>
              <Field required label="間取り">
                <select {...handleInput(room, "arrange", i, numberTransformer)}>
                  <option value="">--間取りを選択--</option>
                  <option value="1">ワンルーム</option>
                  <option value="2">1K</option>
                  <option value="3">1DK</option>
                  <option value="4">1LDK</option>
                  <option value="13">1SDK</option>
                  <option value="14">1SLDK</option>
                  <option value="5">2K</option>
                  <option value="6">2DK</option>
                  <option value="7">2LDK</option>
                  <option value="15">2SDK</option>
                  <option value="16">2SLDK</option>
                  <option value="25">3K</option>
                  <option value="8">3DK</option>
                  <option value="9">3LDK</option>
                  <option value="17">3SDK</option>
                  <option value="18">3SLDK</option>
                  <option value="26">4K</option>
                  <option value="10">4DK</option>
                  <option value="11">4LDK</option>
                  <option value="19">4SDK</option>
                  <option value="20">4SLDK</option>
                  <option value="27">5K</option>
                  <option value="21">5DK</option>
                  <option value="22">5LDK</option>
                  <option value="23">5SDK</option>
                  <option value="24">5SLDK</option>
                  <option value="12">その他</option>
                </select>
              </Field>
              <Field required label="占有面積（m2)">
                <NumericInput
                  min={0}
                  {...handleInput(room, "occupiedArea", i, numberTransformer)}
                />
              </Field>
              <Field required label="家賃">
                <NumericInput
                  min={0}
                  {...handleInput(room, "rentPrice", i, numberTransformer)}
                />
              </Field>
              <Field required label="管理費・共益費">
                <NumericInput
                  min={0}
                  {...handleInput(room, "managementFee", i, numberTransformer)}
                />
              </Field>
              <Field required label="消費税表示">
                <select
                  {...handleInput(room, "consumptionTax", i, numberTransformer)}
                >
                  <option value="1">表示しない</option>
                  <option value="2">表示する</option>
                </select>
              </Field>
              <Field required label="敷金">
                <NumericInput
                  integer
                  min={0}
                  {...handleInput(
                    room,
                    "securityDeposit.value",
                    i,
                    numberTransformer
                  )}
                />
                <select
                  disabled
                  {...handleInput(
                    room,
                    "securityDeposit.unit",
                    i,
                    numberTransformer
                  )}
                >
                  <option value="1">ヵ月</option>
                  <option value="2">円</option>
                </select>
              </Field>
              <Field required label="礼金">
                <NumericInput
                  integer
                  min={0}
                  {...handleInput(room, "keyMoney.value", i, numberTransformer)}
                />
                <select
                  disabled
                  {...handleInput(room, "keyMoney.unit", i, numberTransformer)}
                >
                  <option value="1">ヵ月</option>
                  <option value="2">円</option>
                </select>
              </Field>
              <Field required label="保証金">
                <NumericInput
                  integer
                  min={0}
                  {...handleInput(room, "deposit.value", i, numberTransformer)}
                />
                <select
                  disabled
                  {...handleInput(room, "deposit.unit", i, numberTransformer)}
                >
                  <option value="1">ヵ月</option>
                  <option value="2">円</option>
                </select>
              </Field>
              <Field label="償却金">
                <NumericInput
                  integer
                  min={0}
                  {...handleInput(
                    room,
                    "depreciation.value",
                    i,
                    numberTransformer
                  )}
                />
                <select
                  disabled
                  {...handleInput(
                    room,
                    "depreciation.unit",
                    i,
                    numberTransformer
                  )}
                >
                  <option value="1">ヵ月</option>
                  <option value="2">円</option>
                </select>
              </Field>
              <Field required label="部屋の階部分">
                <select
                  {...handleInput(room, "floorType", i, numberTransformer)}
                >
                  <option value="1">地上</option>
                  <option value="2">地下</option>
                </select>
                <NumericInput
                  integer
                  min={0}
                  {...handleInput(room, "floorNumber", i, numberTransformer)}
                />
              </Field>
              <Field required label="入居可能日">
                <select
                  {...handleInput(
                    room,
                    "availableDate.type",
                    i,
                    numberTransformer
                  )}
                >
                  <option value={0}>設定なし</option>
                  <option value={1}>即入居可</option>
                  <option value={2}>入居可能時期</option>
                </select>

                <div data-role="field-group-block">
                  <div data-role="field-group">
                    <select
                      {...handleInput(
                        room,
                        "availableDate.year",
                        i,
                        numberTransformer
                      )}
                      disabled={room.availableDate.type !== 2}
                    >
                      <option value="">--年--</option>
                      {Array.from({ length: 3 }).map((_, i) => (
                        <option key={i} value={dateHelper.currentYear + i}>
                          {dateHelper.currentYear + i}
                        </option>
                      ))}
                    </select>
                    <select
                      disabled={room.availableDate.type !== 2}
                      {...handleInput(
                        room,
                        "availableDate.month",
                        i,
                        numberTransformer
                      )}
                    >
                      <option value="">--月--</option>
                      {Array.from({ length: 12 }).map((_, i) => (
                        <option key={i} value={i + 1}>
                          {i + 1}
                        </option>
                      ))}
                    </select>
                    <select
                      disabled={
                        !room.availableDate.year || !room.availableDate.month
                      }
                      {...handleInput(
                        room,
                        "availableDate.season",
                        i,
                        numberTransformer
                      )}
                    >
                      <option value="">--時期--</option>
                      {availableDateSeason.map((opt) => (
                        <option key={opt.value} value={opt.value}>
                          {opt.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <label data-hor-align="center">
                    <input
                      disabled={room.availableDate.type !== 2}
                      {...handleInput(
                        room,
                        "availableDate.auto",
                        i,
                        undefined,
                        (e: any) => e.target.checked
                      )}
                      type="checkbox"
                    />
                    <span>
                      即入居可自動切替（時期を過ぎると自動的に即入居可に切り替わります）
                    </span>
                  </label>
                </div>
              </Field>
              <Field required label="PRコメントのタイトル">
                <textarea {...handleInput(room, "prTitle", i)} />
              </Field>
              <Field required label="この物件のおすすめコメント">
                <textarea {...handleInput(room, "prComment", i)} />
              </Field>
              <Field label="電話番号">
                <input {...handleInput(room, "phoneNumber", i)} />
              </Field>
              <Field label="位置">
                <ArticleItems
                  options={positionArticleItems}
                  {...handleInput(
                    room,
                    "positionArticleItems",
                    i,
                    undefined,
                    (value: any) => value
                  )}
                />
              </Field>
              <Field label="条件">
                <ArticleItems
                  options={conditionArticleItems}
                  {...handleInput(
                    room,
                    "conditionArticleItems",
                    i,
                    undefined,
                    (value: any) => value
                  )}
                />
              </Field>
              <Field label="キッチン">
                <ArticleItems
                  options={kitchenArticleItems}
                  {...handleInput(
                    room,
                    "kitchenArticleItems",
                    i,
                    undefined,
                    (value: any) => value
                  )}
                />
              </Field>
              <Field label="バス・トイレ・洗面">
                <ArticleItems
                  options={bathArticleItems}
                  {...handleInput(
                    room,
                    "bathArticleItems",
                    i,
                    undefined,
                    (value: any) => value
                  )}
                />
              </Field>
              <Field label="冷暖房">
                <ArticleItems
                  options={airConditionArticleItems}
                  {...handleInput(
                    room,
                    "airConditionArticleItems",
                    i,
                    undefined,
                    (value: any) => value
                  )}
                />
              </Field>
              <Field label="設備・サービス">
                <ArticleItems
                  options={servicesArticleItems}
                  {...handleInput(
                    room,
                    "servicesArticleItems",
                    i,
                    undefined,
                    (value: any) => value
                  )}
                />
              </Field>
              <Field label="放送・通信">
                <ArticleItems
                  options={communicationArticleItems}
                  {...handleInput(
                    room,
                    "communicationArticleItems",
                    i,
                    undefined,
                    (value: any) => value
                  )}
                />
              </Field>
              <Field label="その他">
                <ArticleItems
                  options={generalArticleItems}
                  {...handleInput(
                    room,
                    "generalArticleItems",
                    i,
                    undefined,
                    (value: any) => value
                  )}
                />
              </Field>
              <Field label="エル目白更新用 投稿ID">
                <input {...handleInput(room, "postId", i)} />
              </Field>
              <Field required label="募集中">
                <select {...handleInput(room, "boshuuchuu", i)}>
                  <option value="">--募集中--</option>
                  {boshuuchuus.map((opt) => (
                    <option key={opt.value} value={opt.value}>
                      {opt.label}
                    </option>
                  ))}
                </select>
              </Field>
            </div>
          </div>
        );
      })}
    </>
  );
};
