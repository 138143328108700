import { faCog, faPlus, faRocket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { useConnectRender } from "use-connect-render";

import Modal from "../../components/Modal";
import SitesModal from "../../components/SitesModal";
import Table from "../../components/Table";

import { useProcess } from "../../hooks/process";
import { useProduct } from "../../hooks/product";

import { buildingTypes } from "../../options";

import classes from "./styles.module.scss";

export interface HomeProps {}

const Home: FC<HomeProps> = () => {
  const { getList } = useProduct();
  const { isRunning: isProcessRunning } = useProcess();
  const [modalData, setModalData] =
    useState<
      | {
          productId: string;
        }
      | undefined
    >();

  const { listen } = useConnectRender("product");
  const [productList] = listen<[Product[]]>("productList");

  useEffect(() => {
    getList();
  }, [getList]);

  const handleClose = useCallback(() => {
    setModalData(undefined);
  }, []);

  const data = useMemo(() => {
    const types = buildingTypes.reduce((obj: Record<string, any>, item) => {
      obj[item.value] = item;
      return obj;
    }, {});

    return productList.map(({ data, ...item }, i) => ({
      no: i + 1,
      ...item,
      ...data,
      type: types[data.type]?.label || data.type,
    }));
  }, [productList]);

  return (
    <div className={classes.page}>
      <Modal
        title="サイトへの投稿を選択してください。"
        isOpen={!!modalData}
        onClose={handleClose}
      >
        {!!modalData && <SitesModal productId={modalData!.productId} />}
      </Modal>
      <Table
        addons={[
          <Link to="/form">
            <FontAwesomeIcon icon={faPlus} />
          </Link>,
        ]}
        data={data}
        columns={[
          {
            title: "No",
            code: "no",
          },
          {
            title: "物件名",
            code: "name",
            render: (text, { id }) => {
              return (
                <Link className={classes.itemLink} to={`/form/${id}`}>
                  {text}
                </Link>
              );
            },
          },
          {
            title: "種別",
            code: "type",
          },
          {
            title: <FontAwesomeIcon icon={faCog} />,
            className: classes.actions,
            render: (_, { roomCount, id }) => {
              const disabledMessage = [];
              if (!roomCount) {
                disabledMessage.push("Rooms is not avaliable");
              }
              if (isProcessRunning) {
                disabledMessage.push("Already one process is running");
              }
              return (
                <div>
                  <button
                    data-tip
                    data-for={`post-btn-${id}`}
                    onClick={() =>
                      !disabledMessage.length &&
                      setModalData({
                        productId: id,
                      })
                    }
                    data-disabled={!!disabledMessage.length}
                  >
                    <FontAwesomeIcon icon={faRocket} />
                  </button>
                  {!!disabledMessage.length && (
                    <ReactTooltip id={`post-btn-${id}`}>
                      {disabledMessage.map((mss, i) => (
                        <div key={i}>{mss}</div>
                      ))}
                    </ReactTooltip>
                  )}
                </div>
              );
            },
          },
        ]}
      />
    </div>
  );
};

export default Home;
