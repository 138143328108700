import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useCallback, useEffect, useState } from "react";
import { SpinnerCircular } from "spinners-react";
import { Redirect } from "react-router-dom";
import { useGlobal } from "use-connect-render";

import { useAuth } from "../../hooks/auth";

import classes from "./styles.module.scss";

export enum MiddlewareMode {
  PUBLIC,
  PRIVATE,
}

export interface MiddlewareProps {
  children: React.ReactElement;
  mode?: MiddlewareMode;
}

interface ModeProps {
  children: React.ReactElement;
  isAuthed: boolean;
  checkedAuthorize: boolean;
}

const Private: FC<ModeProps> = ({ isAuthed, checkedAuthorize, children }) => {
  return isAuthed ? (
    children
  ) : checkedAuthorize ? (
    <Redirect to="/login" />
  ) : (
    <div className={classes.root}>
      <div className={classes.spinnerWrap}>
        <SpinnerCircular size={140} />
        <FontAwesomeIcon className={classes.lock} icon={faLock} />
      </div>
    </div>
  );
};

const Public: FC<ModeProps> = ({ isAuthed, checkedAuthorize, children }) => {
  return checkedAuthorize ? (
    !isAuthed ? (
      children
    ) : (
      <Redirect to="/" />
    )
  ) : (
    <div className={classes.root}>
      <div className={classes.spinnerWrap}>
        <SpinnerCircular size={140} />
        <FontAwesomeIcon className={classes.lock} icon={faLock} />
      </div>
    </div>
  );
};

const Middleware: FC<MiddlewareProps> = ({
  mode = MiddlewareMode.PRIVATE,
  children,
}) => {
  const global =
    useGlobal<{
      isAuthed?: boolean;
    }>();

  const { userProfile, getProfile } = useAuth();
  const [isAuthed, setIsAuthed] = useState(global.isAuthed);
  const [checkedAuthorize, setCheckedAuthorize] = useState(false);

  const getAuthorize = useCallback(async () => {
    await getProfile();
    setCheckedAuthorize(true);
  }, [getProfile]);

  useEffect(() => {
    global.isAuthed = !!userProfile;
    setIsAuthed(global.isAuthed);
  }, [global, userProfile]);

  useEffect(() => {
    if (!isAuthed) {
      getAuthorize();
    }
  }, [isAuthed, getAuthorize]);

  return mode === MiddlewareMode.PRIVATE ? (
    <Private isAuthed={!!isAuthed} checkedAuthorize={checkedAuthorize}>
      {children}
    </Private>
  ) : (
    <Public isAuthed={!!isAuthed} checkedAuthorize={checkedAuthorize}>
      {children}
    </Public>
  );
};

export default Middleware;
