import { faRocket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import Select, { components } from "react-select";
import { useConnectRender } from "use-connect-render";
import ReactTooltip from "react-tooltip";
import moment from "moment";

import { useSite, ProgressStatus, SiteByProduct } from "../../hooks/site";

import classes from "./styles.module.scss";

export interface SitesModalProps {
  productId: string;
}

const SitesModal: FC<SitesModalProps> = ({ productId }) => {
  const refClose = useRef<HTMLElement>(null);
  const { postSites, getByProduct } = useSite();
  const [selectedSites, setSelectedSites] = useState<string[]>([]);
  const { listen } = useConnectRender("site");

  const [sitesByProduct] =
    listen<[Record<string, SiteByProduct[]>]>("sitesByProduct");

  useEffect(() => {
    getByProduct(productId);
  }, [getByProduct, productId]);

  const sites = useMemo(() => {
    return sitesByProduct[productId] || [];
  }, [productId, sitesByProduct]);

  const options = useMemo(() => {
    return sites.map((item) => ({
      lastRuntime: item.lastRuntime
        ? moment(item.lastRuntime).format("Y-M-D H:m:s")
        : "",
      errors: item.errors,
      value: item.id,
      label: item.url,
      status: item.status,
      isDisabled: item.disabled || item.status === ProgressStatus.RUNNING,
    }));
  }, [sites]);

  const Option = (props: any) => {
    const { data } = props;
    const msss = [
      ...(data.status === ProgressStatus.FAIL ? data.errors : []),
    ] as string[];
    if (data.status !== ProgressStatus.RUNNING && !!data.lastRuntime) {
      msss.unshift(`Last run at: ${data.lastRuntime}`);
    }
    return (
      <components.Option {...props}>
        <div
          data-tip
          data-for={`message-${data.value}`}
          className={classes.siteOption}
        >
          {!!msss.length && (
            <ReactTooltip id={`message-${data.value}`}>
              {msss.map((mss: string, index: number) => (
                <div key={index}>{mss}</div>
              ))}
            </ReactTooltip>
          )}

          <span>{data.label}</span>
          <span data-status={data.status}>{data.status}</span>
        </div>
      </components.Option>
    );
  };

  return (
    <div className={classes.root}>
      <Select
        isMulti
        onChange={(e) => setSelectedSites(e.map((item) => item.value))}
        className={classes.select}
        options={options}
        components={{ Option }}
      />
      <div className={classes.buttons}>
        <span ref={refClose} data-role="close" style={{ display: "none" }} />
        <button
          disabled={!selectedSites.length}
          onClick={async () => {
            await postSites(productId, selectedSites);
            refClose.current?.click();
          }}
        >
          <FontAwesomeIcon icon={faRocket} />
        </button>
      </div>
    </div>
  );
};

export default SitesModal;
