import { FC } from "react";
import { LoginForm } from "../../components/LoginForm";

import classes from "./styles.module.scss";

export interface LoginProps {}

const Login: FC<LoginProps> = () => {
  return (
    <div className={classes.page}>
      <LoginForm />
    </div>
  );
};

export default Login;
