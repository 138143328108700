import { useCallback, useEffect, useMemo } from "react";
import { useForm, UseFormProps } from "react-hook-form";
import { useGlobal } from "use-connect-render";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import cloneDeep from "lodash/cloneDeep";
import { MixedSchema } from "yup/lib/mixed";

type Yup = typeof yup & {
  trueNumber: () => yup.NumberSchema<
    number | null | undefined,
    Record<string, any>,
    number | null | undefined
  >;
  file: (options?: FileOptions) => MixedSchema<any, Record<string, any>, any>;
};

export function trueNumber(y: Yup) {
  return y
    .number()
    .nullable(true)
    .typeError("Must be a number")
    .transform((v, o) => (o === "" ? null : v));
}

// default 1 MB
interface FileOptions {
  size?: number;
  label?: string;
  acceptTypes?: string[];
}
export function file(
  y: Yup,
  { size = 1, label, acceptTypes = ["jpg", "jpeg", "png"] }: FileOptions = {}
) {
  return y
    .mixed()
    .test(
      "Size",
      `${
        label ? `${label} file` : "File"
      } size too large, max file size is ${size}`,
      (file: any) => {
        return (
          typeof file === "string" || !file || file.size / 1024 / 1024 <= size
        );
      }
    )
    .test(
      "Type",
      `${label ? `${label} file` : "File"} has invalid type`,
      (file: any) => {
        return (
          typeof file === "string" ||
          !file ||
          acceptTypes.some((type) => file.type.includes(type))
        );
      }
    )
    .label("File");
}

export function useClearFormCache() {
  const { getValues } = useForm();
  const global = useGlobal<{
    shouldCache?: true;
    previousValues: Record<string, ReturnType<typeof getValues> | undefined>;
  }>({
    previousValues: {},
  });

  useEffect(() => {
    return () => {
      for (const k of Object.keys(global.previousValues)) {
        delete global.previousValues[k];
      }
      delete global.shouldCache;
    };
  }, [global]);
  return null;
}

export function useFormValidate<TContext extends object = object>(
  getSchema: (y: Yup) => Record<string, any>,
  formProps: Omit<UseFormProps<any, TContext>, "resolver"> = {}
) {
  const schema = useMemo(() => {
    const _Y = yup as Yup;
    _Y.trueNumber = function () {
      return trueNumber(this);
    };
    _Y.file = function (options?: FileOptions) {
      return file(this, options);
    };
    return yup.object().shape(getSchema(_Y));
    // eslint-disable-next-line
  }, []);

  const { getValues, setValue, formState, ...configs } = useForm({
    resolver: yupResolver(schema),
    ...formProps,
  });

  const global = useGlobal<{
    shouldCache?: true;
    previousValues: Record<string, ReturnType<typeof getValues> | undefined>;
  }>({
    shouldCache: true,
    previousValues: {},
  });

  const cachePreviousValues = useCallback(
    (cacheId: string) => {
      if (global.shouldCache) {
        global.previousValues[cacheId] = cloneDeep(getValues()) as ReturnType<
          typeof getValues
        >;
      }
    },
    [global, getValues]
  );

  const bindFromPreviousValues = useCallback(
    (cacheId: string, fallback = () => {}) => {
      if (global.previousValues[cacheId]) {
        const values = cloneDeep(global.previousValues[cacheId]) as Record<
          string,
          any
        >;
        Object.keys(values).forEach((k: any) => {
          setValue(k, values[k]);
        });
        global.previousValues[cacheId] = undefined;
      } else {
        fallback();
      }
    },
    [global, setValue]
  );

  const getPreviousValues = useCallback(
    (cacheId: string, fallback = () => {}) => {
      if (global.previousValues[cacheId]) {
        const values = cloneDeep(global.previousValues[cacheId]) as Record<
          string,
          any
        >;
        return values;
      } else {
        fallback();
      }
    },
    [global]
  );

  const { errors } = formState;

  const getListErrorMessages: any = useCallback(
    (errors: any, prefix: string = "") => {
      return Object.values(errors).flatMap((error) => {
        const errs = Array.isArray(error) ? error : [error];
        return errs.flatMap((err, i) => {
          if (typeof err !== "object") {
            return [];
          }
          const subPrefix = `${errs.length > 1 ? `[${i}] ` : ""}`;
          if (err.message && typeof err.message === "string") {
            return `${prefix}${subPrefix}${err.message}`;
          }
          return getListErrorMessages(err as any, subPrefix);
        });
      });
    },
    []
  );

  return {
    ...configs,
    errorMessages: getListErrorMessages(errors) as string[],
    formState,
    getValues,
    setValue,
    getPreviousValues,
    cachePreviousValues,
    bindFromPreviousValues,
  };
}

export type UseForm = ReturnType<typeof useFormValidate>;
